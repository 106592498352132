
<template>
  <div id="divKB" class="w-16rem hidden" style="display: flex;margin: auto;position: absolute;top: 235px;left: 85px;justify-content: center;border: solid black 0px;flex-wrap: wrap;
    z-index: 5;background-color: white;border-radius: 8px">    
    <div v-for="(letter,i) in Alpha" :key="i" style="display: flex;margin: auto;
      justify-content: center;border: solid black 0px;flex-wrap: wrap;z-index: 5">
      <input v-if="letter!='Clear' && letter!='Space'" type="button" class="w3-btn w3-blue w-3rem h-2rem" :value="letter" style="padding-top: 2px;margin-top: 2px;border-radius: 6px" />
      <input v-else type="button" class="w3-btn w3-blue w-6rem h-2rem" :value="letter" style="padding-top: 2px;margin-top: 2px;border-radius: 6px" placeholder="123-45-678"/>
    </div>
    <input class="w-input w-full" placeholder="Search" type="text" id="inpSearch" style="height:30px;margin-top: 2px;border-radius: 8px;background-color: #cccccc;margin: 2px">
  </div>
  <div id="accordion" style="border: solid black 0px;margin-top: -15px;height: 60px">
    <!-- divTypes Albums/Singles/Members/Yuotube/SiriusXM -->
    <div id="divTypes" class="" style="height: 60px">
      <div v-if="isPlayMobile() == true" id="divMain" style="overflow: hidden;margin-bottom: 0px">                
          <table style="border: solid purple 0px;overflow: hidden" class="w-full">
            <tr style="text-align: center;font-size: 16px;height: 60px">
              <td><input @click="showLanguages(0,'Albums')" type="button" class="w3-button w3-red" value="Albums" style="width: 83px;border-radius: 8px;border: solid grey 1x;
                box-shadow: black 3px 3px 3px"></td>
              <td><input @click="showLanguages(0,'Singles')" type="button" class="w3-button w3-purple" value="Singles" style="width: 83px;border-radius: 8px;border: solid grey 1px;
                box-shadow: black 3px 3px 3px"></td>
              <td><input @click="showDJ(0,'DJ')" type="button" class="w3-button w3-green" value="DJ" style="width: 85px;width: 83px;border-radius: 8px;border: solid grey 1px;
                box-shadow: black 3px 3px 3px"></td>
              <td><input @click="showYoutubes(0,'Youtubes')" type="button" class="w3-button w3-orange" value="Videos" style="width: 83px;border-radius: 8px;border: solid grey 1px;
                box-shadow: black 3px 3px 3px"></td>
            </tr>           
            <tr style="width: 100px" hidden>                
              <td style="border: solid black 0px">
                <img class="" :src="rootpath+'/Albums/album.jpg'" @click="showLanguages(0,'Albums')" style="display: flex;margin: auto;border-radius: 50%;border: solid black 0px;width: 95px"/>              
              </td>
              <td style="border: solid black 0px">
                <img class="" :src="rootpath+'/Singles/album.jpg'" @click="showLanguages(1,'Singles')" style="display: flex;margin: auto;border-radius: 50%;border: solid black 0px;width: 85px"/>              
              </td>
              <td style="border: solid black 0px">
                <img class="" :src="rootpath+'/Playlists/album.jpg'" @click="showDJ(2,'DJ')" style="display: flex;margin: auto;border: solid black 0px;width: 90px;border-radius: 50%"/>              
              </td>
              <td style="border: solid black 0px">
                <img class="" :src="rootpath+'/Youtubes/album.jpg'" @click="showYoutubes(3,'Youtubes')" style="display: flex;margin: auto;border: solid black 0px;width: 90px;border-radius: 50%"/>              
              </td>                                          
            </tr>          
          </table>
      </div>  
      <div v-else id="divMain" class="divMain w-full" style="display: flex;margin: auto;
        border: solid red 0px;border-radius: 0px">

        <table style="border: solid purple 0px">
          <tr style="text-align: center;font-size: 30px" class="w-full">
            <td style="width: 200px" @click="showLanguages(1,'Singles')">Albums</td>
            <td style="width: 200px" @click="showLanguages(1,'Singles')">Singles</td>
            <td style="width: 200px" @click="showDJ(2,'DJ')">DJ</td>
            <td style="width: 200px" @click="showYoutubes(3,'Youtubes')">Videos</td>
          </tr>           
          <tr>        
        
            <td style="border: solid black 0px">
              <img class="rainbowder3" :src="rootpath+'/Albums/album.jpg'" @click="showLanguages(0,'Albums')" style="display: flex;margin: auto;border-radius: 50%;border: solid black 0px;width: 95px"/>              
            </td>
            <td style="border: solid black 0px">
              <img class="rainbowder3" :src="rootpath+'/Singles/album.jpg'" @click="showLanguages(1,'Singles')" style="display: flex;margin: auto;border-radius: 50%;border: solid black 0px;width: 85px"/>              
            </td>
            <td style="border: solid black 0px">
              <img class="rainbowder3" :src="rootpath+'/Playlists/album.jpg'" @click="showDJ(2,'DJ')" style="display: flex;margin: auto;border: solid black 0px;border-radius: 50%;width: 95px"/>              
            </td>
            <!-- <td style="border: solid black 0px">
              <img class="" :src="rootpath+'/Playlists/album.jpg'" @click="showPlaylists(2,'Playlists')" style="display: flex;margin: auto;border: solid black 0px;border-radius: 0%;width: 125px"/>              
            </td> -->
            <td style="border: solid black 0px">
              <img class="rainbowder3" :src="rootpath+'/Youtubes/album.jpg'" @click="showYoutubes(3,'Youtubes')" style="display: flex;margin: auto;border: solid black 0px;border-radius: 50%;width: 95px"/>              
            </td>                                          
          </tr>          
        </table>
      </div> 
    </div>  
    
    <div id="divPlayer" class="divPlayer hidden" style="display: flex;margin: 10px auto; justify-content: center;border: solid black 0px">
      <audio onended="songEnded()" class="player" 
        id="listen"
        src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/Beatles%20-%20I%20Saw%20Her%20Standing%20There.mp3?alt=media&token=a13b0174-dee3-40d6-aeb9-ab183014cbd1" 
        controls="controls"
        display="flex"
      ></audio>
    </div>  

    <div style="position:absolute;top: 150px;margin-left: -3px">
      <!-- divLanguages Spanish/English -->

      <div @click="showColors()" style="display: flex;margin: auto;position: absolute;top: -65px;left: 0px;justify-content: center;z-index: 1;">
        <img src="https://cdn3.iconfinder.com/data/icons/soda-red/512/Color-picker3.png" style="width: 10%" alt="">
      </div>

      <div id="divLanguages" class="divLanguages w-26rem" style="display: none;margin: auto;border: solid black 0px;justify-content: center;margin-bottom: 3px">
        <div v-if="selType!=''" style="display: flex;margin: auto;justify-content: center;width: 25%">
          <div v-for="(language,i) in types[selectedType].Languages" :key="i" style="display: flex;margin: auto;
            justify-content: center;border: solid red 0px;">     
            <input @click="showGenres(i,language.Language,documents,types)" id="btnSearchId" class="btn btn-success" 
              style="padding-top: 5px;width: 83px;border-radius: 8px;border: solid black 0px;box-shadow: black 3px 3px 3px;margin-right: 2px;color: black;font-size: 13px;box-shadow: black 3px 3px 3px;height: 30px" 
              type="button" :value="language.Language" >                 
            <div class="hidden">
              <input type="checkbox" class="w3-check" style="margin-left: 5px;margin-right: 5px" checked/> 
            </div>
          </div>             
        </div>        
      </div> 

      <!--Genres -->
      <div id="divGenres" class="divGenres" style="display: none;margin: auto;border: solid black 0px;border-radius: 8px;padding-top: 1px;
        justify-content: center;flex-wrap: wrap;margin-right: 1px; overflow-x: hidden;margin-top: 10px;margin-left: 75px;flex-wrap: wrap;width: 215px">
        <div v-if="selType=='Albums' && selLanguage!=''" style="display: flex;margin: auto;flex-wrap: wrap">
          <div v-for="(genre,i) in types[selectedType].Languages[selectedLanguage].Genres" :key="i" style="flex-wrap: wrap">
            {{ genre.genre }}  
            <input v-if="genre.Genre!='Rock en Espanol'" @click="showSubgenres(i,genre.Genre)" id="btnSearchId" class="btn btn-success" 
              style="padding-top: 5px;width: 103px;border-radius: 8px;border: solid black 0px;box-shadow: black 3px 3px 3px;margin-right: 4px;margin-bottom: 5px;color: black;
                box-shadow: black 3px 3px 3px;height: 30px;font-size: 13px;padding-bottom: 5px" type="button" :value="genre.Genre" />
            <input v-else @click="showSubgenres(i,genre.Genre)" id="btnSearchId" class="btn btn-info" 
              style="padding-top: 5px;width: 103px;border-radius: 8px;border: solid black 0px;box-shadow: black 3px 3px 3px;margin-right: 4px;margin-bottom: 5px;color: black;
                box-shadow: black 3px 3px 3px;height: 30px;font-size: 13px;padding-bottom: 5px" type="button" value="Rock Espanol" />                     
            <div class="">
              <input type="checkbox" class="w3-check hidden" style="margin-left: 5px;margin-right: 0px" checked/> 
            </div>
          </div>             
        </div>
      </div> 

      <div id="divSubgenres" class="divSubgenres" style="display: none;margin: auto;border: solid black 0px;border-radius: 8px;padding-top: 1px;
        flex-wrap: wrap;overflow-x: hidden;margin-top: 5px;margin-left: 73px;flex-wrap: wrap;width: 215px">
        <div v-if="selectedType==0 || selectedType==1" style="display: flex;margin: auto;flex-wrap: wrap;justify-content: center">
          <div v-for="(subgenre,i) in types[selectedType].Languages[selectedLanguage].Genres[selectedGenre].Subgenres" :key="i" class="subgenres" 
            style="display: flex;flex-direction: row; border: solid black 0px;border-radius: 8px;">   
            <div class="w-full" v-if="selType=='Albums' && selLanguage!='' && selGenre!=''" 
              style="display: inline-flex;margin: auto;flex-wrap: nowrap;border-radius: 10px;overflow-x: auto">
              <input @click="showArtists(i,subgenre.Subgenre,documents,types)" id="btnSearchId" class="btn btn-success" 
                style="padding-top: 5px;width: 103px;border-radius: 8px;border: solid black 0px;box-shadow: black 3px 3px 3px;margin-right: 4px;margin-bottom: 5px;color: black;box-shadow: black 3px 3px 3px;height: 30px;
                  font-size: 13px;padding-bottom: 5px"
                 type="button" :value="subgenre.Subgenre" />          
              <input type="checkbox" class="w3-check hidden" style="margin-right: 5px" checked/>
            </div>    
            <button v-else style="color: white;background-color: red;font-size: 16px;text-align: left;" 
              @click="showArtists(subgenre.id,subgenre.Subgenre,documents,types)" class="w3-button accordion_options w-24rem">
              {{ subgenre.Subgenre }}
            </button>                                                       
          </div> 
        </div>              
      </div> 

      <div id="divArtists" class="divContainer w-16rem" style="display: none;margin: auto;position: absolute;top: 250px;border: solid black 0px;
        flex-wrap: wrap;margin-left: 70px;max-height: 144px;;overflow-y: auto;"> 
        <div class="w-full" style="display: flex;justify-content: left;flex-wrap: wrap;margin-top: 10px">
          <div v-for="(artist,i) in documents" :key="i" class="artists" style="display: flex;margin-bottom: 2px;
            justify-content: left;flex-direction: row;border: solid black 0px;flex-wrap: wrap;max-height: 200px"> 
            <button v-if="selType=='Albums' && selArtist!=''" style="color: white;background-color: grey;font-size: 12px;text-align: left;border-radius: 8px" @click="showAlbums(i,artist)"
              class="w3-button w-full">{{ artist }}</button>
            <input v-else-if="selType=='Singles'" @click="showAlbums(i,artist)" id="btnSearchId" class="w3-button w3-pink w-16rem" 
              style="display: flex;border: solid black 1px;border-radius: 8px;font-size: 12px;height: 25px;padding-top: 3px;margin-left: 0px;margin-right: 1px;justify-content: left;text-align: left" type="button" 
              :value="artist.artist" />
            <input type="checkbox" class="w3-check" style="margin-left: 10px" checked hidden/>                           
          </div>           
        </div>             
      </div>

      <!--Artist if Singles -->
      <div id="divSingles" class="divContainer w-16rem" style="display: none;border: solid black 0px;max-height: 385px;
        overflow-y: auto;overflow-x: hidden;justify-content: center;flex-wrap: wrap;margin-top: 0px;position: absolute;top: 235px;margin-left: 70px">
        <div style="display: flex;margin: auto;justify-content: left;flex-wrap: wrap">
          <div  v-for="(album,i) in filteredAlbumsStartsWith" :key="i"
            style="display: flex;margin: auto;justify-content: left;border: solid rgb(0, 0, 0) 0px;border-radius: 0px;margin: 1px;max-height: 500px;">
            <!-- {{ selType }}/{{ selLanguage }}/{{ selGenre }}/{{ selSubgenre }}/{{ selArtist }}/{{ album.Albums }} -->
            <img v-if="selType=='Albums' && selLanguage!='' && selGenre!=''" :src="rootpath+'/'+selType+'/'+
              selLanguage+'/'+selGenre+'/'+selSubgenre+'/'+selArtist+'/'+album.Albums+'/album.jpg'" @click="showSongs(i,album.Albums)" 
              style="display: flex;margin: auto;border: solid black 3px"/>
            <button v-else  style="margin-top:3px;color: black;background-color: white;height: 30px;padding-left: 5px;
              text-align: left;margin-bottom: 0px;border: solid black 1px;border-radius: 8px;font-size: 11px" @click="showSongs(i,album.Albums)" class="w3-button w-14rem">{{ album.Albums }}</button>
            <input v-if="selType=='Albums'" type="checkbox" class="w3-check hidden" style="margin-left: 5px;margin-right: 0px" checked/>
            <input v-else type="checkbox" class="w3-check" style="margin-left: 5px" checked/>  
          </div>
        </div>             
      </div> 

      <div>
        <div v-if="selArtist" class="w-14rem" style="display: flex;margin: auto;justify-content: center;flex-wrap: wrap;text-align: center;border: solid black 0px;
            margin-bottom:5px;position: sticky;margin-top: 10px">
            
            <div class="w-full" id="divArtist" style="font-size: 13px; color: black;font-weight: 700;height: 20px;margin-top: 0px;border: solid black 0px">{{ selArtist }}</div>            
            <div class="w-full" id="divAlbum" style="font-size: 13px; color: black;font-weight: 700;height: 20px;margin-top: 0px;border: solid black 0px">{{ selAlbum }}</div>                          
          
        </div> 
        <div id="divSongs" class="divContainer w-18rem" style="display: block;border: solid black 0px;overflow-y: auto;
          overflow-x: hidden;margin-left: 59px;max-height: 433px;justify-content: center;margin-top: 8px">            
           
            <div v-for="(album,i) in filteredSongsStartsWith" :key="i" class="album w-full" style="display: flex;margin: auto;
              justify-content: left;flex-direction: row;border: solid black 0px">    
              <button style="color: white;background-color: purple;font-size: 12px;" class="w3-button w-full hidden">
              {{ album.Albums }}</button> 
              <div class="w-full" style="display: flex;margin: auto;justify-content: left;flex-wrap: wrap">
                <div v-for="(song,j) in album.Songs" :key="j" selSong="ALoversConcerto.mp3" class="songs w-17rem" style="display: flex;margin-left: 5px;
                  justify-content: left;flex-direction: row;border: solid black 0px"> 
                  <div style="border: solid black 0px;margin: 0px">
                    <input v-if="song.LoveIt[0].Keep=='true'" :id="'chkSong'+j" type="checkbox" class="w3-check" style="margin-top: 0px" checked/>
                    <input v-else :id="'chkSong'+j" type="checkbox" class="w3-check" style="margin-top: 0px" />                              
                  </div>
                  <div style="padding-bottom: 5px">
                    <button :id="'btn'+j" v-if="album.Songs[j].DisplayTitle.split('-').length>2" @click="handleClick(j,selType,selLanguage,selGenre,selSubgenre,selArtist,selAlbum,
                      song,album.Songs)" style="margin-top: 1px;color: black;background-color: #dcdcdc;font-size: 12px;text-align: left;box-shadow: 3px 3px 4px rgba(0,0,0,.5);
                      width: 185px;border-radius: 3px;font-weight: 700;border: solid black 1px;margin-left: -5px;padding-top: 5px;padding-left: 5px;height: 30px" class="w3-btn btn-success play">
                      {{ album.Songs[j].DisplayTitle.split('-')[1].split('(')[0] }}
                    </button>
                    <button :id="'btn'+j" v-else-if="album.Songs[j].DisplayTitle.split('-').length>1" @click="handleClick(j,selType,selLanguage,selGenre,selSubgenre,selArtist,selAlbum,
                      song,album.Songs)" style="margin-top: 1px;color: black;background-color: #dcdcdc;font-size: 12px;text-align: left;box-shadow: 3px 3px 4px rgba(0,0,0,.5);
                      width: 185px;border-radius: 3px;font-weight: 700;border: solid black 1px;margin-left: -5px;padding-top: 5px;padding-left: 5px;height: 30px" class="w3-btn btn-success play">
                      {{ album.Songs[j].DisplayTitle.split('-')[1].split('(')[0] }}
                    </button>
                    <button :id="'btn'+j" v-else @click="handleClick(j,selType,selLanguage,selGenre,selSubgenre,selArtist,selAlbum,
                      song,album.Songs)" style="margin-top: 1px;color: black;font-size: 12px;text-align: left;box-shadow: 3px 3px 4px rgba(0,0,0,.5);
                      width: 185px;border-radius: 3px;font-weight: 700;border: solid black 1px;margin-left: -5px;padding-top: 5px;padding-left: 5px;height: 30px" class="btn btn-success btn-white play">
                      {{ album.Songs[j].DisplayTitle.split('(')[0].split(',')[0] }}
                    </button>
                  </div>  
                  <input :id="'addDJ'+j" type="buton" class="w3-button w-3rem" @click="addToDJ(j,selType,selLanguage,selGenre,selSubgenre,selArtist,selAlbum,song,
                  album.Songs)" style="margin-left: 5px;border-radius: 8px;height: 30px;font-weight: 100" value="DJ" checked hidden/>                  
                </div>           
              </div>                                      
            </div>                                      
        </div>

        <div id="divAlbums" class="w-17rem" style="display: inline-flex;position: absolute;top: 325px;border: solid black 0px;flex-wrap: nowrap;flex-direction: row;overflow-x: auto;
          overflow-y: hidden;margin-left: 60px">    
          <div v-for="(document, index) in sortedData" :key="index" style="">
            <!--- <router-link @click="ToggleMembers=false,ToggleNavbar=false" id="router" :to="{ name: 'PlaylistDetails', params: { id: playlist.id }}"  style="width: 100%;;border: solid black 10px;"> -->
            <!--- <div  v-bind:id="index" style="background-color: #c7d3e2;" @click="handleClick" class="artist-name">{{ playlist.artist }}</div> -->
            <div v-if="isMobile()" style="border: solid black 0px;">        
              <div style="margin-left: 0px;margin-right: 0px;overflow-x: auto;flex-wrap: wrap">   
                <div style="border: solid black 0px" class="w-17rem">           
                  <div class="w-full" style="display: flex;margin: 0px auto;justify-content: center;text-align: center;border: solid black 0px;flex-wrap: wrap">                
                    <div class="w-full" id="divArtist" style="font-size: 12px; color: black;font-weight: 600;height: 20px;margin-top: 2px;border: solid black 0px">{{ document.artist }}</div>            
                    <div class="w-full" id="divAlbum" style="font-size: 12px; color: black;font-weight: 600;height: 20px;margin-top: 0px;border: solid black 0px">{{ document.album }}</div>                          
                  </div>
                  <div @click="scrollAlbums(document.type,document.language,document.genre,document.subgenre,document.artist,document.album)" style="display: flex;margin: auto;scroll-behavior: smooth;justify-content: center;">                                                                
                    <img v-if="document.type=='Albums'" class="thumbnail-mobile" :src="'https://storage.cloud.google.com/staging.deploy-template-397316.appspot.com/Albums/'+document.language+'/'+document.genre+'/'+document.subgenre+'/'+document.artist+'/'+document.album+'/album.jpg'" 
                      style="width: 210px;height: 150px;margin-right: 0px;border: solid white 1px;border-radius: 8px"/>
                    <img v-else-if="document.type=='Singles'" class="thumbnail-mobile" :src="'https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/album.jpg?alt=media&token=1ac3e52b-670f-43db-b009-ee68697533da'" 
                      style="width: 210px;height: 150px;;margin-left: 0px;margin-right: 0px;border: solid white 1px;border-radius: 8px"/>                                                
                  </div>                                                        
                </div>             
              </div>                           
            </div>
          </div>       
        </div>

        <div v-if="getUniques" style="display: flex;margin: auto;position: absolute;top: 580px;justify-content: left;flex-wrap: wrap;margin-top: 10px;width: 390px">
          <div id="divSearchBy" style="margin-bottom: 0px" class="w-26rem">
            <multiselect v-model="value" :options="getUniques" :multiple="true" :close-on-select="false" :clear-on-select="true"
                        :preserve-search="true" placeholder="Select some Artists" label="artist" track-by="album" :preselect-first="false">
              <template #selection="{ values, isOpen }">
                <span class="multiselect__single"
                      v-if="values.length"
                      v-show="!isOpen">{{ values.length }} Artists selected</span>
              </template>
            </multiselect>
          </div>                
        </div>
       
        <div id="divSelectedArtists" class="hidden">{{ value }}        
        </div> 
        
        <div id="accJukebox" style="border: solid black 0px;border-radius: 0px;padding-left: 0px;padding-bottom: 0px;position: absolute;top: 750px;width: 360px;justify-content: left;">                          
          <div id="divColors" style="display: flex;margin: auto;flex-flow: nowrap;justify-content: space-evenly">
            <div>
              <button @click="setColorArray(0)" type="button" style="width: 50px;background-color: #d50000;border-radius: 8px"></button>
            </div>
            <div>
              <button @click="setColorArray(1)" type="button" style="width: 50px;background-color: #c51162;border-radius: 8px"></button>
            </div>
            <div>
              <button @click="setColorArray(2)" type="button" style="width: 50px;background-color: #aa00ff;border-radius: 8px"></button>
            </div>
            <div>
              <button @click="setColorArray(3)" type="button" style="width: 50px;background-color: #6200ea;border-radius: 8px"></button>
            </div>
            <div>
              <button @click="setColorArray(4)" type="button" style="width: 50px;background-color: #00bfa5;border-radius: 8px"></button>
            </div>
            <div>
              <button @click="setColorArray(5)" type="button" style="width: 50px;background-color: #ffd600;border-radius: 8px"></button>
            </div>
            <div>
              <button @click="setColorArray(6)" type="button" style="width: 50px;background-color:#ff6d00;border-radius: 8px"></button>
            </div>
          </div>
        </div>

      </div> 

    </div>

    <div id="divRateit" v-if="selSong!=''" class="divRateIt w-full" style="display: none;margin: 0px auto;justify-content: center;flex-wrap: nowrap;
      border: solid 0px">
      <div>
        <div style="display: flex;margin: auto;justify-content: center;">
          <div class="w-full" style="display: flex;margin: auto;justify-content: center;border: solid 0px">
            <div @click="keepIt(1)"><img src="../assets/images/yes.jpg" alt="" style="width: 80px;margin-right:20px"></div>
          </div>    
          <div class="w-full" style="display: flex;margin: auto;justify-content: center;border: solid 0px">
            <div @click="keepIt(0)"><img src="../assets/images/no.jpg" alt="" style="width: 70px"></div>
          </div>
        </div>                 
      </div>
    </div>    

    <!-- Search videos by Language -->
    <div id="divYTContainer" class="divContainer divYTContainer w-full" style="display: none;margin: auto; 
      justify-content: center;border: solid rgb(27, 168, 74) 0px;margin-top: 15px;margin-bottom: 5px">
      <div id="divYouTubeContainer" class="divYouTubeContainer" style="display: flex;margin: auto;flex-wrap: wrap">
        <div style="display:flex;margin: auto;flex-wrap: wrap;justify-content: center" class="w-full">      
          <div class="w-full" style="display: flex;margin: auto;
            justify-content: center;border: solid rgb(41, 139, 65) 0px;margin-bottom: 0px">
            <div class="w-full" style="display: flex;margin: auto;flex-wrap: wrap;margin-top: 5px">
              <div id="divYTLanguages" class="divYTLanguages w-full" style="display: flex;margin: auto;
                justify-content: center;;margin-bottom: 5px;text-align: center">
                <input @click="setYT('Spanish')" id="btnSearchIdSpanish" class="w3-button w3-margin w-10rem" style="display: flex;
                  float: auto;text-align: center;border: solid black 0px;border-radius: 5px;justify-content: center;height: 50px;font-size: 14px" type="button" 
                  value="Spanish" />
                <input @click="setYT('Members')" id="btnSearchIdMembers" class="w3-button w3-margin w-10rem" style="display: flex;
                  float: auto;text-align: center;border: solid black 0px;border-radius: 5px;justify-content: center;height: 50px;font-size: 14px" type="button" 
                  value="Members" />
                <input @click="setYT('English')" id="btnSearchIdEnglish" class="w3-button w3-margin w-10rem" style="display: flex;
                  float: auto;text-align: center;border: solid black 0cqb;border-radius: 5px;justify-content: center;height: 50px;font-size: 14px" type="button" 
                  value="English" />
              </div>
              <div v-if="isMobile()" style="display: flex;margin: auto;flex-wrap: nowrap;justify-content: left;border: solid black 0px;overflow-y: hidden;">
                <div v-for="(link,j) in youtubeLinks" :key="j" style="display: inline-flex;flex-wrap: nowrap;justify-content: center">
                  <div class="divYTSpanishGenres w-full" v-if="selLanguage=='Spanish' && link.language=='Spanish'" id="divYTSpanishGenres" 
                      style="display: flex;flex-wrap: nowrap;border: solid 0px;margin: 0px;flex-direction: column;">                    
                      <button @click="setYTGenre(link.genre)" style="background-color: transparent;border: 0px;font-size: 18px;margin-top: 5px">{{ link.genre }}</button>     
                      <img :id="'img'+link.genre.replace(' ','').replace(' ','')" class="imgGenre" @click="setYTGenre(link.genre)" :src="link.image" alt="" style="width: 100px;height: 75px;margin-right: 3px;border: solid black 3px;border-radius: 10px">                                       
                  </div>
                  <div class="divYTMembers w-full" v-if="selLanguage=='Members' && link.language=='Members'" id="divYTMembers" 
                      style="display: flex;flex-wrap: wrap;border: solid 0px;margin-right: 0px;flex-direction: column;width: 100px">  
                      <button @click="setYTGenre(link.genre)" style="background-color: transparent;border: 0px;font-size: 18px;margin-top: 5px;width: 100px;">{{ link.genre }}</button>                  
                      <img :id="'img'+link.genre" class="imgGenre" @click="setYTGenre(link.genre)" :src="link.image" alt="" style="width: 100px;height: 75px;margin-right: 3px;border: solid black 0px;border-radius: 10px">
                  </div>
                  <div class="divYTEnglishGenres w-full" v-if="selLanguage=='English' && link.language=='English'" id="divYTEnglishGenres" 
                      style="display: flex;flex-wrap: wrap;border: solid 0px;margin: 0px;flex-direction: column;">                    
                      <button @click="setYTGenre(link.genre)" style="background-color: transparent;border: 0px;font-size: 18px;margin-top: 5px;">{{ link.genre }}</button>
                      <img :id="'img'+link.genre.replace(' ','').replace(' ','')" class="imgGenre" @click="setYTGenre(link.genre)" :src="link.image" alt="" style="width: 100px;height: 75px;margin-right: 3px;border: solid black 3px;border-radius: 10px">
                  </div>                  
                </div>
              </div>
              <div v-else style="display: flex;margin: auto;flex-wrap: nowrap;justify-content: left;border: solid black 0px;overflow-y: auto;">
                <div v-for="(link,j) in youtubeLinks" :key="j" style="display: inline-flex;flex-wrap: nowrap;justify-content: center">
                  <div class="divYTSpanishGenres w-full" v-if="selLanguage=='Spanish' && link.language=='Spanish'" id="divYTSpanishGenres" 
                      style="display: flex;flex-wrap: nowrap;border: solid 0px;margin: 0px;flex-direction: column;">                    
                      <button @click="setYTGenre(link.genre)" style="background-color: transparent;border: 0px;font-size: 18px;margin-bottom: 3px">{{ link.genre }}</button>     
                      <img  :id="'img'+link.genre.replace(' ','').replace(' ','')" class="imgGenre" @click="setYTGenre(link.genre)" :src="link.image" alt="" style="width: 100px;height: 75px;border: solid black 2px;border-radius: 10px;margin: 5px">                                       
                  </div>
                  <div class="divYTMembers w-full" v-if="selLanguage=='Members' && link.language=='Members'" id="divYTMembers" 
                      style="display: flex;flex-wrap: wrap;border: solid 0px;margin: 0px;flex-direction: column">  
                      <button @click="setYTGenre(link.genre)" style="background-color: transparent;border: 0px;font-size: 18px;margin-bottom: 0px;">{{ link.genre }}</button>                  
                      <img :id="'img'+link.genre" class="imgGenre" @click="setYTGenre(link.genre)" :src="link.image" alt="" style="width: 100px;height: 75px;border: solid black 1px;border-radius: 10px;margin: 5px">
                  </div>
                  <div class="divYTEnglishGenres w-full" v-if="selLanguage=='English' && link.language=='English'" id="divYTEnglishGenres" 
                      style="display: flex;flex-wrap: wrap;border: solid 0px;margin: 0px;flex-direction: column;">                    
                      <button @click="setYTGenre(link.genre)" style="background-color: transparent;border: 0px;font-size: 18px;margin-bottom: 3px;">{{ link.genre }}</button>
                      <img :id="'img'+link.genre.replace(' ','').replace(' ','')" class="imgGenre" @click="setYTGenre(link.genre)" :src="link.image" alt="" style="width: 100px;height: 75px;border: solid black 2px;border-radius: 10px;margin: 5px">
                  </div>                  
                </div>
              </div>
            </div>      
          </div> 
        </div>
      </div>      
    </div> 

    <div id="divDJ" class="divContainer divDJ w-full" style="display: none;border: solid black 0px;
      overflow-y: auto;overflow-x: hidden;max-height: 900px;margin-top: 25px">
                      
        <div v-for="(song,i) in requests[0].Songs" :key="i" class="playlist w-full" style="display: flex;margin-bottom: 3px;
          justify-content: left;flex-direction: row;border: solid black 0px">    
          <button style="color: white;background-color: rgb(255, 146, 152);font-size: 16px;text-align: left;border-radius: 8px" 
            @click="handleDJ(i,song.Title)" class="w3-button accordion_options w-full">{{ song.DisplayTitle }}
          </button>              
        </div>           
      
    </div>  

    <!-- <div id="divPlaylists" class="divContainer divPlaylists" style="display: none;border: solid black 0px;
      overflow-y: auto;overflow-x: hidden;max-height: 300px">
                
      <div v-if="curPLLanguage=='Spanish'" style="display: flex;margin-bottom: 3px;justify-content: left;flex-wrap: wrap">
        <div v-for="(genre,i) in splGenres" :key="i" class="playlist" style="display: flex;margin-bottom: 3px;
          justify-content: left;flex-direction: row;border: solid black 0px"> 
          <button style="color: white;background-color: rgb(82, 79, 82);font-size: 16px;text-align: left" 
            @click="showSelectedGenrePlayLists(i,genre)"
            class="w3-button accordion_options w-23rem">{{ genre.genre }}</button>
          <input @click="createPLSearchString(genre.genre)" type="checkbox" class="w3-check" style="margin-left: 5px;margin-right: 10px" />                           
        </div>           
      </div>             
      <div v-else-if="curPLLanguage=='English'" style="display: flex;margin-bottom: 3px;justify-content: left;flex-wrap: wrap">
        <div v-for="(genre,i) in eplGenres" :key="i" class="playlist" style="display: flex;margin-bottom: 3px;
          justify-content: left;flex-direction: row;border: solid black 0px"> 
          <button style="color: white;background-color: rgb(82, 79, 82);font-size: 16px;text-align: left" 
            @click="showSelectedGenrePlayLists(i,genre)"
            class="w3-button accordion_options w-23rem">{{ genre.genre }}</button>  
          <input @click="createPLSearchString(genre.genre)" type="checkbox" class="w3-check" style="margin-left: 5px" />                           
        </div>           
      </div>             
    </div> -->  

    <!-- <div id="divMembers" class="divContainer divMembers" style="display: none;border: solid black 0px;
      overflow-y: auto;overflow-x: hidden;">              
      <div style="display: flex;margin-bottom: 3px;justify-content: left;flex-wrap: wrap">
        <div v-for="(member,i) in members" :key="i" class="members" style="display: flex;margin-bottom: 3px;
          justify-content: left;flex-direction: row;border: solid black 0px"> 
          <button style="color: white;background-color: purple;font-size: 16px;text-align: left" 
            @click="showSelectedMemberPlayLists(i,member)"
            class="w3-button accordion_options w-21rem">{{ member.name }}</button>  
          <input type="checkbox" class="w3-check" style="margin-left: 10px" />                           
        </div>           
      </div>             
    </div> -->  

    <div id="divGenreSelectedPlaylistSongs" class="divContainer divGenreSelectedPlaylistSongs" style="display: none;border: solid black 0px;
      overflow-y: auto;overflow-x: hidden;max-height: 555px">           
      <div style="display: flex;margin-bottom: 0px;justify-content: left;flex-wrap: wrap">
        <div v-for="(playlist,i) in filteredGenrePlaylistSongs" :key="i" class="members" style="display: flex;margin-bottom: 3px;
          justify-content: left;flex-direction: row;border: solid black 0px;flex-wrap: wrap;">
          <div v-for="(song,j) in playlist.Songs" :key="j" class="songs w-27rem" style="display: flex;margin-bottom: 3px;
            justify-content: left;flex-direction: row;border: solid black 0px"> 
            <div v-if="song.Title.indexOf('�') == -1" style="display:flex;margin: auto;flex-wrap: nowrap">
              <button v-if="song.Title.indexOf('-') != -1" style="color: white;background-color: rgb(133, 126, 126);font-size: 16px;text-align: left;margin-right: 5px" 
                @click="handlePlaylist(j,song.Title,playlist.Songs)" class="w3-button accordion_options w-21rem">{{ song.Title.split('/')[7].replace(/%20/g,' ').split('-')[0].replace('.mp3','') }}</button>
              <button v-else-if="song.Title.indexOf('_') != -1" style="color: white;background-color: rgb(133, 126, 126);font-size: 16px;text-align: left;margin-right: 5px" 
                @click="handlePlaylist(j,song.Title,playlist.Songs)" class="w3-button accordion_options w-21rem">{{ song.Title.split('/')[7].replace(/%20/g,' ').split('_')[0].replace('.mp3','') }}</button>              
              <button v-else style="color: white;background-color: rgb(126, 133, 131);font-size: 16px;text-align: left;margin-right: 5px" 
                @click="handlePlaylist(j,song.Title,playlist.Songs)" class="w3-button accordion_options w-21rem" contenteditable="true">{{ song.Title.split('/')[7].replace(/%20/g,' ').replace('.mp3','') }}</button>  
              <input :id="'chkPLSong'+j" type="checkbox" class="w3-check" style="margin-left: 5px;" checked/>  
            </div>                                    
          </div>
        </div>           
      </div>             
    </div>

    <div id="divSelectedGenrePlaylists" class="divContainer divSelectedGenrePlaylists" style="display: none;border: solid black 0px;
      overflow-y: auto;overflow-x: hidden;max-height: 555px">           
      <div style="display: flex;margin-bottom: 3px;justify-content: left;flex-wrap: wrap">
        <div v-for="(playlist,i) in filteredGenrePlaylist" :key="i" class="members" style="display: flex;margin-bottom: 3px;
          justify-content: left;flex-direction: row;border: solid black 0px;flex-wrap: wrap;">
          <button style="color: white;background-color: rgb(255, 146, 152);font-size: 16px;text-align: left;" 
            @click="showGenreSelectedPlayList(i,playlist)" class="w3-button accordion_options w-23rem">{{ playlist.Playlist.replace('.m3u','') }}
          </button>  
          <input type="checkbox" class="w3-check" style="margin-left: 5px" checked/>                                       
        </div>           
      </div>             
    </div>
    
    <div id="divSelectedPlaylists" class="divContainer divSelectedPlaylists" style="display: flex;border: solid black 0px;
      overflow-y: auto;overflow-x: hidden;max-height: 550px">            
      <div style="display: flex;margin-bottom: 3px;justify-content: left;flex-wrap: wrap">
        <div v-for="(playlist,i) in filteredbyPlaylist" :key="i" class="playlist" style="display: flex;margin-bottom: 3px;
          justify-content: left;flex-direction: row;border: solid black 0px;flex-wrap: wrap;"> 
          <div v-for="(title,j) in playlist.Songs" :key="j" class="members" style="display: flex;margin-bottom: 3px;
            justify-content: left;flex-direction: row;border: solid black 0px"> 
            <button style="color: white;background-color: rgb(162, 180, 238);font-size: 16px;text-align: left;" 
              @click="showGenreSelectedPlayList(j,playlist)" class="w3-button accordion_options w-23rem">{{ playlist.Subgenres }}/{{ title.Title }}</button>  
            <input type="checkbox" class="w3-check" style="margin-left: 10px" checked/>                           
          </div>
        </div>           
      </div>             
    </div>

    <div id="divMemberSelectedPlaylist" class="divContyainer divMemberSelectedPlaylist" style="display: none;border: solid black 0px;
      overflow-y: auto;overflow-x: hidden;">test              
      <div style="display: flex;margin-bottom: 3px;justify-content: left;flex-wrap: wrap">
        <div v-for="(genre,i) in filteredGenrePlaylist" :key="i" class="Playlist" style="display: flex;margin-bottom: 3px;
          justify-content: left;flex-direction: row;border: solid black 0px;flex-wrap: wrap;"> 
          <div v-for="(playlist,j) in genre.playlists" :key="j" class="members" style="display: flex;margin-bottom: 3px;
            justify-content: left;flex-direction: row;border: solid black 0px"> 
            <button style="color: white;background-color: rgb(216, 145, 13);font-size: 16px;text-align: left;" 
              @click="showGenreSelectedPlayListSongs(j,playlist.songs)"
              class="w3-button accordion_options w-21rem">'{{ playlist.playlist }}'</button>  
            <input type="checkbox" class="w3-check" style="margin-left: 10px" />                           
          </div>
        </div>           
      </div>             
    </div>

    <div id="divGenreSelectedPlaylistSongss" class="divContainer divGenreSelectedPlaylistSongss" style="display: none;border: solid black 0px;
      overflow-y: auto;overflow-x: hidden;">          
      <div style="display: flex;margin-bottom: 3px;justify-content: left;flex-wrap: wrap">
        <div v-for="(song,i) in filteredGenrePlaylistSongs.Songs" :key="i" class="members" style="display: flex;margin-bottom: 3px;
          justify-content: left;flex-direction: row;border: solid black 0px;flex-wrap: wrap;"> {{ i  }}          
          <button style="color: white;background-color: rgb(37, 183, 12);font-size: 16px;text-align: left;" 
            @click="showGenreSelectedPlayListSongs(i,song)"
            class="w3-button accordion_options w-21rem">{{ song.DisplayTitle }}</button>  
          <input type="checkbox" class="w3-check" style="margin-left: 10px" />                           
        </div>           
      </div>             
    </div>

    <div id="divYoutubes" class="divContainer divYoutubes w-full" style="display: none;margin: auto;border: solid red 0px;overflow-x: auto;justify-content: left">            
      <div style="display: flex;margin-bottom: 3px;justify-content: left;flex-wrap: nowrap;
        border: solid black 0px;overflow-x: auto;margin: auto">
        <div v-for="(youtube,i) in filteredYouTubes" :key="i" class="youtubes" style="display: flex;margin-bottom: 3px;
          justify-content: left;flex-direction: row;border: solid black 0px">
          
          <div v-if="youtube.genre.toUpperCase().includes(curUser.toUpperCase())" style="display: flex;margin: auto;justify-content: left;flex-wrap: nowrap">
            <button style="margin-left: 5px;border: solid black 0px;border-radius: 10px;font-weight: 800;height: 200px" class="w3-button accordion_options w-26rem">
              <div>
                <img @click="playYoutubes(i,youtube.path,youtube.thumbnail)" :src="youtube.thumbnail" alt="" style="display: flex;margin: auto;width: 275px;height: 150px;
                  border: solid black 5px;border-radius: 8px;" />
              </div>
              <label class="labelTitle" style="display:flex;margin: auto;font-size: 16px;font-weight: 500;justify-content: center;">{{ youtube.title }}</label>              
              <div class="w-full" style="margin-left: 0px"><label class="labelArtist" style="display: flex;margin: auto;font-size: 14px;font-weight: 500;
                border: solid black 0px;justify-content: center;">{{ youtube.artist }}</label></div>                            
              <div style="display: flex;margin: auto;border: solid black 0px;justify-content: center;">
                <input type="checkbox" class="w3-check" checked/>
              </div>              
              
            </button>                                            
          </div> 
          <div v-else style="display: flex;margin: auto;justify-content: left;flex-wrap: nowrap">
            <button style="margin-left: 5px;border: solid black 3px;border-radius: 10px;font-weight: 800" class="w3-button accordion_options w-18rem">
              <div>
                <img @click="playYoutubes(i,youtube.path,youtube.thumbnail)" :src="youtube.thumbnail" alt="" style="display: flex;margin: auto;width: 200px;height: 125px;
                  border: solid black 1px" />
              </div>
              <label class="labelTitle" style="display:flex;margin: auto;font-size: 16px;font-weight: 500;justify-content: center;">{{ youtube.title }}</label>
              <div style="display: inline-flex;margin: auto;border: solid black 1px;justify-content: center;" class="w-full">
                <div class="w-13rem" style="margin-left: 15px"><label class="labelArtist" style="display: flex;margin: auto;font-size: 14px;font-weight: 500;border: solid black 0px;justify-content: center;">{{ youtube.artist }}</label></div>
                <div><input type="checkbox" class="w3-check" style="display: flex;margin: auto;border: solid black 1px;margin-top: 0px" /></div>              
              </div>
            </button>                                           
          </div>          
        </div>           
      </div>             
    </div>

    <div v-if="isMobile()==true" class="divContainer w-full" id="divIFRYouTubes" style="display: none;margin: auto;border: solid 0px;border-radius: 8px;">
      <iframe id="ifrYouTubes"
        style="display: flex;margin: auto;justify-content: center;flex-wrap: wrap;border: solid black 0px;width: 100%;height: 300px;border-radius: 8px;" 
        src="" 
        title="description"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        loop=1
        >      
      </iframe>    
    </div>
    <div v-else class="divContainer w-full" id="divIFRYouTubes" style="display: none;margin: auto;border: solid 0px;border-radius: 8px">
      <iframe id="ifrYouTubes"
        style="display: flex;margin: auto;justify-content: center;flex-wrap: wrap;border: solid black 0px;width: 100%;height: 1000px;border-radius: 8px;" 
        src="" 
        title="description"
        autoplay=1
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        loop=1
        >
      </iframe>    
    </div>
    
    <div id="divFooter" class="nowrap divContainer w-full" style="display: none;margin: auto;border: solid black 0px;margin-top: 0px;
      justify-content: left">
      <div class="" style="display: flex;margin: auto;justify-content: center;text-align: center;border: solid black 0px;margin-right: 10px;margin-top: 20px">      
        <input type="button" id="btnBack" class="w3-button w3-blue" style="border-radius: 8px;height: 35px;padding-top: 5px" @click="goBack(1,selType,selLanguage,selGenre,selSubgenre,selArtist,selAlbum,selSong)" value="Return">
      </div>     
      <div id="divPreview" class="divPreview" style="display: inline-flex;margin: auto;justify-content: center;margin-top: 25px">
        <div class="nowrap w-full" style="display: flex;margin: auto;justify-content: left;border: solid black 0px">
          <label style="margin-top: 7px">Preview</label>
          <input id="chkPreview" @click="toggleDivRateIt()" class="w3-check" type="checkbox" />
        </div>
      </div> 
     
      <div id="divArtistSearch" class="divSearch w-30rem w3-margin" style="display: none;margin: auto;justify-content: left
        ;border: solid black 0px;flex-wrap: wrap;">
        <label>Artist</label >
        <input id="inpSearchArtist" @blur="blur('inpSearchArtist')" @click="setSearchBy('Artist')" class="w3-input" style="display: flex;margin: auto;justify-content: left;border-radius: 0px;margin-right: 5px;
          border: solid rgb(0, 0, 0) 1px" type="text" placeholder="search artist" 
          v-model="Artist" />             
      </div> 
      
      <div id="divTitleSearch" class="divSearch w-30rem w3-margin" style="display: none;margin: auto;justify-content: left
        ;border: solid black 0px;flex-wrap: wrap;">
        <label>Title</label >
        <input id="inpSearchTitle" @blur="blur('inpSearchTitle')" @click="setSearchBy('Title')" class="w3-input" style="display: flex;margin: auto;justify-content: left;border-radius: 0px;margin-right: 0px;
          border: solid rgb(0, 0, 0) 1px" type="text" placeholder="search title" 
          v-model="Title" />             
      </div>
      
      <div id="divSearchPLString" class="">
        <input id="inpSearchId" class="w-13rem w3-input" style="display: none;margin: auto auto;
          border: solid black 1px;border-radius: 5px;margin-bottom: 10px" type="text" :placeholder="'Enter ' + curLevel" 
          v-model="Playlist" />             
      </div> 
    </div>

    <div id="divPreviewTimer" class="divContainer divPreviewTimer w-full" style="display: none;margin: auto;justify-content: center;border: solid 0px">
      <div style="display: flex;margin: auto;justify-content: center;border: solid 0px">
        <input type="button" style="background-color: rgb(76, 209, 27); color: white;border: solid black 5px" class="btn w3-margin" @click="setPreview(4)" value="4 seconds">
        <input type="button" style="background-color: rgb(255, 255, 255); color: black;border: solid black 5px" class="btn w3-margin" @click="setPreview(8)" value="8 seconds">
        <input type="button" style="background-color: rgb(231, 22, 22); color: white;border: solid black 5px" class="btn w3-margin" @click="setPreview(12)" value="12 seconds">
      </div>   
    </div>
  </div>

  <div class="">
  <input id="inpDesign" @click="designMode()" class="w3-btn hidden" value="Deploy">
  
  <div v-if="isPlayMobile()" style="display: block;margin: auto;z-index: 0;justify-content: center;align-items: center;border: solid black 0px;margin-top: -30px" class="w-full">

    <div style="position:absolute;top: 120px;border: solid black 0px" class="w-26rem">
      
      <div style="text-align: center;margin-top: 3px;margin-left:-5px" class="divTheJB">
        <img id="imgAlbum" class="album-image"
            src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/theJB.png?alt=media&token=5ac98cc8-4b40-4cbb-bce4-64ed1d1cd1e5" alt="" 
            style="width: 40%;border: solid black 0px;z-index: 1">
      </div> 

      <div class="divDancers w-full" style="display: flex;margin: auto;margin-left: -5px;justify-content: center;text-align: center;position: absolute;top: 90px;border: solid black 0px">
        <img id="imgAlbum" class="album-image"
            src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/dancers.png?alt=media&token=8f7c0b92-e20f-4f5b-b34b-4422fe645598" alt="" 
            style="width: 50%;border: solid black 0px;z-index: 1;box-shadow: black 0px 0px 7px;margin: 5px" >      
      </div>   

      <div class="divSaturday" style="display: flex;margin: auto;justify-content: center;text-align: center;margin-left: 70px;position: absolute;top: 390px;border: solid black 0px">
        <img id="imgAlbum"
            src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/crown.jpg?alt=media&token=207987db-0dc3-4670-9766-3e1dfb6a51e2" alt="" 
            style="border: solid white 2px;width: 210px;;height: 150px;z-index: 1;border-radius: 8px;box-shadow: black 0px 0px 7px;margin: 5px">
      </div>          

      <div class="divRockola" style="display: flex;margin: auto;justify-content: center;text-align: center;margin-left: 38px;position: absolute;top: 250px;border: solid black 0px">
        <div style="text-align: center;">
          <img id="imgAlbum" class="album-image"
              src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/rockola.png?alt=media&token=c2137590-d2fb-4dc3-a43f-4195379b58c9" alt="" 
              style="width: 80%;border: solid black 0px;z-index: 1">
        </div> 
      </div>

      <div style="text-align: center;" hidden>
        <img id="imgAlbum" class="album-image"
            src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/album.jpg?alt=media&token=fcc27267-a351-4ee0-9368-8e7e6df85ac3" alt="" 
            style="width: 100%;border: solid black 2px;z-index: 1">
      </div>  

    </div>

    <img id="imgJukebox" class="jukebox-image"
    src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/jukebox.jpg?alt=media&token=62d291e0-9102-487a-83fb-bb8512a3c4db" alt="" 
    style="z-index: -1;width: 100%;border: solid white 2px;margin-top: 30px;box-shadow: pink 0px 0px 20px;border-radius: 8px;">

    <!-- <img id="imgInnOut" class="in-out" src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/innout.png?alt=media&token=ba39fd1a-5a34-451a-9943-a26d31020067"
        style="display: flex;margin: auto;position: relative;z-index: -1;
            width: 57%;height: 130px;margin-top: -600px;border-radius: 5px;border: solid rgb(201, 183, 206) 0px;"
            alt=""> -->




    

  <div v-if="Design==true" id="divMainx" style="display: block;position: relative;margin: auto;justify-content: center;width: 60%;height: 269px;z-index: 1;margin-top: 0px;border: solid black 5px" hidden>
    <img id="imgBG1" src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/woodbg.jpg?alt=media&token=9cb43b1a-60be-42cf-8847-a61a6032a38a" 
        style="width: 205px; height: 258px;
            border-radius: 0px;border: solid rgb(0, 0, 0) 0px;"
            alt="">
    <img id="imgBG2" src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/woodbg%20-%20Copy.jpg?alt=media&token=cd633325-fa33-46b4-9d78-4fc11ce6b598" 
        style="width: 100%;height: 95px;margin-top: 2px;
            border-radius: 0px;border: solid rgb(0, 0, 0) 1px"
            alt="" hidden>            
    <img id="imgMat" src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/ttbase.png?alt=media&token=45fe181c-4b52-4681-9c5c-10ff0976798d" 
        style="display: flex;margin: auto;position: relative;z-index: 1;                
            width: 85%;margin-top: -255px;margin-left: 15px;border-radius: 0px;border: solid rgb(201, 183, 206) 0px;"
            alt="">               
    <img id="imgRecord" src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/album.jpg?alt=media&token=fcc27267-a351-4ee0-9368-8e7e6df85ac3" 
        style="display: flex;margin: auto;position: relative;z-index: 1;                
            width: 70%;margin-top: -159px;margin-left: 30px;border-radius: 100%;border: solid rgb(201, 183, 206) 0px;"
            alt=""> 
    <img id="imgArmBase" src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/armbase.png?alt=media&token=ab1f175f-c102-4f2f-b7cd-38b0f978563c"
        style="display: flex;margin: auto;position: relative;z-index: 0;                
            width: 20%;margin-top: -130px;margin-left: 165px;border-radius: 0px;border: solid rgb(201, 183, 206) 0px;"
            alt="">                
    <img id="imgNeedle" src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/pioneerNeedle.png?alt=media&token=46bd8382-4269-4ef8-b476-36d82b9f7f47" 
        style="display: flex;margin: auto;position: relative;z-index: 1;
            width: 39%;margin-top: -155px;margin-left: 150px;border-radius: 5px;border: solid rgb(201, 183, 206) 0px;"
            alt="">                    
  </div>

<div v-else id="divMainx" style="display: block;position: relative;margin: auto;justify-content: center;width: 60%;z-index: 1;margin-top: -16px;">
    <img id="imgBG1" src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/woodbg.jpg?alt=media&token=cd633325-fa33-46b4-9d78-4fc11ce6b598" 
        style="width: 100%; height: 195px;
            border-radius: 0px;border: solid rgb(0, 0, 0) 3px"
            alt="">
    <img id="imgBG2" src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/woodbg.jpg?alt=media&token=cd633325-fa33-46b4-9d78-4fc11ce6b598" 
        style="width: 100%;height: 91px;margin-top: 5px;
            border-radius: 0px;border: solid rgb(0, 0, 0) 3px"
            alt=""> 
    <img id="imgAmp" src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/teacamp.jpg?alt=media&token=ceb0df8b-3ba6-4562-b5e8-5533795895b5" 
        style="display: flex;margin: auto;position: relative;z-index: 2;
            width: 100%;margin-top: 5px;border-radius: 0px;border: solid rgb(201, 183, 206) 0px;box-shadow: 0px 0px 0px rgba(0, 126, 253, 0.803);"
            alt="">  
    <img id="imgMat" src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/ttbase.png?alt=media&token=6adf971a-abb1-424e-b527-b090c66add66" 
        style="display: flex;margin: auto;position: relative;z-index: 1;                
            width: 78%;margin-top: -355px;margin-left: 5px;border-radius: 0px;border: solid rgb(201, 183, 206) 0px;"
            alt="">        
    <img id="imgRecord" src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/beatleslp%20-%20express.png?alt=media&token=638f3251-c560-43a6-af36-9c498a70f148" 
        style="display: flex;margin: auto;position: relative;z-index: 1;                
            width: 65%;margin-top: -30px;margin-left: 18px;border-radius: 0px;border: solid rgb(201, 183, 206) 0px;"
            alt=""> 
    <img id="imgArmBase" src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/armbase_adobe_express.png?alt=media&token=5b93bb93-ec46-43a8-abb7-8bb662fe255c"
        style="display: flex;margin: auto;position: relative;z-index: 0;                
            width: 15%;margin-top: -150px;margin-left: 160px;border-radius: 0px;border: solid rgb(201, 183, 206) 0px;"
            alt="">                
    <img id="imgNeedle" src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/pioneerNeedle.png?alt=media&token=43765062-005d-4b4b-8501-fb56f7ab26df" 
        style="display: flex;margin: auto;position: relative;z-index: 1;
            width: 38%;margin-top: -180px;margin-left: 125px;border-radius: 5px;border: solid rgb(201, 183, 206) 0px;"
            alt="">
</div>

<!-- <input type="range" orient="vertical" /> -->

<!-- <img id="imgMat" class="mat" src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/ttbase%20-%20Copy_adobe_express%20(1).png?alt=media&token=2945ee18-8591-45e6-a46e-f7b1629a2dcc" 
    style="display: flex;margin: auto;position: relative;z-index: 0;                
        width: 43%;margin-top: -197px;margin-left: 85px;border-radius: 0px;border: solid rgb(201, 183, 206) 0px;"
        alt=""> 
<img id="imgRecord" class="record" src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/beatles_adobe_express.png?alt=media&token=a49cf7d5-ed51-4544-a6fc-63afaba332f5" 
    style="display: flex;margin: auto;position: relative;z-index: 0;                
        width: 35%;margin-top: -153px;margin-left: 100px;border-radius: 0px;border: solid rgb(201, 183, 206) 0px;"
        alt=""> -->                          
  </div>
  <div v-else>
    <img id="imgJukebox" class="jukebox-image" 
    src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/jukeboxadobe_express.png?alt=media&token=4ac9da81-5f62-48bd-80f2-6b7a292ab772" alt="" 
    style="z-index: 0;position: relative;width: 100%;top: 30px;left: 0px;">  

    <img class="beatles-album" src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/beatles.gif?alt=media&token=e7c0adb0-3e40-4b99-9356-deb63ba2fe76" 
    style="width: 50%;margin-top: -2580px;margin-left: 0px;border: solid rgb(0, 0, 0) 20px" alt="">
  </div> 

  <div v-if="isPlayMobile" >    
    <div id="divPower" v-if="Design==true" style="width: 56%;height: 30px;" hidden>    
      <div v-if="Power==false || Lights==false" style="position: relative;margin: auto;margin-top: -188px;border: solid rgb(255, 255, 255) 0px;z-index: 3">
        <img @click="Lights=false" id="imgVUMeterOff" src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/vumeterLeftOff.jpg?alt=media&token=b461f5a1-5751-406f-bdb2-69d070b680c0" 
            style="display: flex;margin: auto;position: relative;z-index: 2;                
            width: 25.25%;height: 21.5px;margin-top: 3px;margin-left: 100.0px;border-radius: 0px;border: solid rgb(247, 245, 247) 0px;" alt="">
        <img @click="Lights=false" id="imgVUMeterOff" src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/vumeterRightOff.jpg?alt=media&token=aa0e30c6-a07e-4a88-a84f-9309c6456a24" 
            style="display: flex;margin: auto;position: relative;z-index: 2; width: 25.75%;height: 22.5px;margin-top: -22.00px;margin-left: 145.9px;border-radius: 0px;
            border: solid rgb(247, 245, 247) 0px;" alt="">                          
      </div>
    </div>
    <div id="divPower" v-else style="width: 60%;height: 30px;" class="hidden">    
      <div v-if="Power==false || Lights==false">
        <img @click="Lights=false" id="imgVUMeterOff" src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/vumeterLeftOff.jpg?alt=media&token=8f444f97-e3ac-49a9-8d56-02401b7f9e0c" 
          style="display: flex;margin: auto;position: relative;z-index: 5;width: 24.5%;height: 21px;margin-top: -185px;margin-left: 107.5px;border-radius: 0px;
          border: solid rgb(247, 245, 247) 0px;" alt="">
        <img @click="Lights=false" id="imgVUMeterOff" src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/vumeterRightOff.jpg?alt=media&token=a81df23c-19b8-4374-b851-fd6ddb9ec1d4" 
          style="display: flex;margin: auto;position: relative;z-index: 5;width: 24.5%;height: 21px;margin-top: -23px;margin-left: 22.9px;border-radius: 0px;border: solid rgb(247, 245, 247) 0px;" alt="">                          
        </div>
      </div>
  </div> 
</div>

  <!-- selType '{{ selType }}'<br>
  selLanguage '{{ selLanguage }}'<br>
  selGenre '{{ selGenre }}'<br>
  selSubgenre '{{ selSubgenre }}'<br>
  selArtist '{{ selArtist }}'<br>
  selAlbum '{{ selAlbum }}'<br> -->
  
</template>
<script>
  import { ref, onMounted } from 'vue';
  import { useToast } from "primevue/usetoast";
  import { rootPath  } from '../assets/json/rootPath.json'
  import { Jukebox } from '../assets/json/jukebox.json'
  import { Empty } from '../assets/json/jukebox.json'
  import { Types } from '../assets/jbLinks.json'

  import useStorage from '@/composables/useStorage'
  import useCollection from '@/composables/useCollection'
  import getCollection from '@/composables/getCollection'
  import getDocument from '@/composables/getDocument'
  import getUser from '@/composables/getUser'
  import { timestamp } from '@/firebase/config'

  import { SPLGenres } from '../assets/splLinks.json'
  import { EPLGenres } from '../assets/eplLinks.json'

  import { Members } from '../assets/json/members.json'
  import { Youtubeplaylists } from '../assets/json/youtubeplaylists.json'
  import { Youtubes } from '../assets/json/youtubes.json'
  import { YouTubeLinks } from '../assets/youtubelinks.json'
  import { Playlist } from '../assets/json/playlists.json'
  import { Requests } from '../assets/json/dj.json'
  import DJ from '/djJSON.js'
  import Alpha from '../assets/json/alpha.json'
  import PlayerView from '@/components/PlayerView.vue'
  import '../style.css';
  import Multiselect from 'vue-multiselect'

  import { getFirestore, collection, getDocs, doc } from "firebase/firestore";

  //import { query, orderBy, limit } from "firebase/firestore";  

  //import { doc } from "firebase/firestore";

  export default {
      name: 'App',
      props: ['id'],
      components: { PlayerView, Multiselect },  
      mounted: function() {
        this.isMobile(); 
        $("#divColors").toggle();       
        //inpDesign.click();    
        //inpDesign.click();        
      },    
      methods: { 
        designMode() {    
            //alert(this.isPlayMobile())    
                
            if(inpDesign.value == "Design" && this.isPlayMobile())
            {
                imgStreamBG.style.width = "56%"
                divMainx.style.width = "56%"
                divPower.style.width = "56%"
                inpDesign.value = "Deploy"                
                ////console.log("Design")
                inpDesign.style.top = "0px"
                inpDesign.style.display = "none"    
            } else {
                imgStreamBG.style.width = "60%"
                divMainx.style.width = "60%"
                divPower.style.width = "60%"
                inpDesign.value = "Design"
                ////console.log("Deploy")       
                inpDesign.style.top = "0px"                                          
            }
            
        },
        isPlayMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                //alert("Playlist Is Mobile = true");
                return true
            } else {
                //alert("Playlist Is Mobile = false");
                return false
            }
        }, 
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                //console.log("Is Mobile = true");
                return true
            } else {
                //console.log("Is Mobile = false");
                return false
            }
        },          
        onChange(e) {
          //console.log("id: " + e);
        },
        onFocus(e) {
          //console.log("onFocus: " + e);           
        },
        onBlur(e) {
          //console.log("onBlur: " + e);            
        },     
      },
      data() {
        return { 
          Design: true,
          Artist: '',
          Title: '',
          searchBy: 'Artist',
          sortbyData: 'artist',       
          value: [],
          options: [
            {name: 'Vue.js', language: 'JavaScript'},
            {name: 'Adonis', language: 'JavaScript'},
            {name: 'Rails', language: 'Ruby'},
            {name: 'Sinatra', language: 'Ruby'},
            {name: 'Laravel', language: 'PHP'},
            {name: 'Phoenix', language: 'Elixir'}
          ],
        };
      },
      computed: { 
        getUniques() {
          if(this.getData) {
            let tempArray = [];
            for (let item of this.sortedData) {
              (tempItem => {
                if (!(tempItem.length > 0 && tempItem.find(x => x.artist === item.artist))) tempArray.push(item);
              })(tempArray.filter(x => x.artist === item.artist))
            }
            return tempArray;
          }
        },      
        getData() {  
          if(this.selArtists) {
            //alert("6")
            //this.selArtists = "Elvis Presley,Michael Jackson"
            return this.documents.filter(
              (documents) =>               
              documents.artist == this.selArtists.split(',')[0] ||
              documents.artist == this.selArtists.split(',')[1] ||
              documents.artist == this.selArtists.split(',')[2] ||
              documents.artist == this.selArtists.split(',')[3] ||
              documents.artist == this.selArtists.split(',')[4] ||
              documents.artist == this.selArtists.split(',')[5] ||
              documents.artist == this.selArtists.split(',')[6] ||
              documents.artist == this.selArtists.split(',')[7] ||
              documents.artist == this.selArtists.split(',')[8] ||
              documents.artist == this.selArtists.split(',')[9] ||

              documents.artist == this.selArtists.split(',')[10] ||
              documents.artist == this.selArtists.split(',')[11] ||
              documents.artist == this.selArtists.split(',')[12] ||
              documents.artist == this.selArtists.split(',')[13] ||
              documents.artist == this.selArtists.split(',')[14] ||
              documents.artist == this.selArtists.split(',')[15] ||
              documents.artist == this.selArtists.split(',')[16] ||
              documents.artist == this.selArtists.split(',')[17] ||
              documents.artist == this.selArtists.split(',')[18] ||
              documents.artist == this.selArtists.split(',')[19] ||

              documents.artist == this.selArtists.split(',')[20] ||
              documents.artist == this.selArtists.split(',')[21] ||
              documents.artist == this.selArtists.split(',')[22] ||
              documents.artist == this.selArtists.split(',')[23] ||
              documents.artist == this.selArtists.split(',')[24] ||
              documents.artist == this.selArtists.split(',')[25] ||
              documents.artist == this.selArtists.split(',')[26] ||
              documents.artist == this.selArtists.split(',')[27] ||
              documents.artist == this.selArtists.split(',')[28] ||
              documents.artist == this.selArtists.split(',')[29] ||

              documents.artist == this.selArtists.split(',')[30] ||
              documents.artist == this.selArtists.split(',')[31] ||
              documents.artist == this.selArtists.split(',')[32] ||
              documents.artist == this.selArtists.split(',')[33] ||
              documents.artist == this.selArtists.split(',')[34] ||
              documents.artist == this.selArtists.split(',')[35] ||
              documents.artist == this.selArtists.split(',')[36] ||
              documents.artist == this.selArtists.split(',')[37] ||
              documents.artist == this.selArtists.split(',')[38] ||
              documents.artist == this.selArtists.split(',')[39] ||

              documents.artist == this.selArtists.split(',')[40] ||
              documents.artist == this.selArtists.split(',')[41] ||
              documents.artist == this.selArtists.split(',')[42] ||
              documents.artist == this.selArtists.split(',')[43] ||
              documents.artist == this.selArtists.split(',')[44] ||
              documents.artist == this.selArtists.split(',')[45] ||
              documents.artist == this.selArtists.split(',')[46] ||
              documents.artist == this.selArtists.split(',')[47] ||
              documents.artist == this.selArtists.split(',')[48] ||
              documents.artist == this.selArtists.split(',')[49]
            )
          } else if(this.documents!=null) {
            if(this.selType && !this.selLanguage) {
              //alert("1")
              return this.documents.filter(
                (documents) =>            
                documents.type == this.selType
              )              
            } else if(this.selType && this.selLanguage && !this.selGenre) {
              //alert("2")
              return this.documents.filter(
                (documents) =>  
                documents.type == this.selType &&
                documents.language == this.selLanguage
              )
            } else if(this.selType && this.selLanguage && this.selGenre && !this.selSubgenre) {
              //alert("3")
              return this.documents.filter(
                (documents) =>  
                documents.type == this.selType &&
                documents.language == this.selLanguage &&
                documents.genre == this.selGenre
              )
            } else if(this.selType && this.selLanguage && this.selGenre && this.selSubgenre && !this.selArtist) {
              //alert("4")
              return this.documents.filter(
                (documents) =>  
                documents.type == this.selType &&
                documents.language == this.selLanguage &&
                documents.genre == this.selGenre &&
                documents.subgenre == this.selSubgenre
              )
            } else if(this.selType && this.selLanguage && this.selGenre && this.selSubgenre && this.selArtist && !this.selAlbum) {
              //alert("5")
              return this.documents.filter(
                (documents) =>  
                documents.type == this.selType &&
                documents.language == this.selLanguage &&
                documents.genre == this.selGenre &&
                documents.subgenre == this.selSubgenre && 
                documents.artist == this.selArtist 
              )
            } else if(this.selType && this.selLanguage && this.selGenre && this.selSubgenre && this.selArtist && this.selAlbum) {
              //alert("6")
              return this.documents.filter(
                (documents) =>  
                documents.type == this.selType &&
                documents.language == this.selLanguage &&
                documents.genre == this.selGenre &&
                documents.subgenre == this.selSubgenre && 
                documents.artist == this.selArtist &&
                documents.album == this.selAlbum
              )
            } else {
              return this.documents.filter(
                (documents) =>  
                documents.type == "Spanish"
              )
            }

          } 
        },
        sortedData() {
          if(this.documents!=null) {
            return this.getData.sort((a, b) =>
              a[this.sortbyData].localeCompare(b[this.sortbyData]),                    
            );
          }
        },
        filteredType() {
          if(this.documents!=null) {
            return this.documents.filter(
              (documents) =>            
              documents.type == this.selType
            );
          }
        },    
        filteredLanguage() {
          if(this.documents!=null) {
            return this.documents.filter(
              (documents) =>            
              documents.language == this.selLanguage 
            );
          }
        },        
        filteredGenre() {
          if(this.documents!=null) {
            return this.documents.filter(
              (documents) =>            
              documents.genre == this.selGenre
            );
          }
        },        
        filteredSubgenre() {
          if(this.documents!=null) {
            return this.documents.filter(
              (documents) =>            
              documents.subgenre == this.selSubgenre
            );
          }
        },
        filteredLanguageStartsWith() {
          //alert("filteredLanguageStartsWith")
          return this.documents.filter(
              (documents) =>            
              documents.language == this.selLanguage 
              //&&
              //jukebox.Artists.toLowerCase()  
              //.startsWith(this.Artist.trim().toLowerCase())                           
            );
        },             
        filteredArtistsStartsWith() {
          //alert("filteredArtistsStartsWith")
          //alert(this.selType)
          if(this.selType!='') {
            var lookup = {};
            var items = Jukebox.filter(
              (documents) =>            
              documents.Languages == this.selLanguage 
              && documents.Genres == this.selGenre           
              && documents.Subgenres == this.selSubgenre 
              //&& jukebox.Artists == this.selArtist 
              //&& jukebox.Albums == this.selAlbum
              //&& jukebox.Songs[0].Title == '01_Adoro_Bronco_Adios Disco 1.mp3'  
              && jukebox.Artists.toLowerCase()  
              .startsWith(this.Artist.trim().toLowerCase())                           
            );
            var result = [];

            for (var item, i = 0; item = items[i++];) {
              var artist = item.Artists;

              if (!(artist in lookup)) {
                lookup[artist] = 1;
                //alert("lookup: " + JSON.stringify(lookup))
                result.push(artist);
              
              }
              else {
                //alert("artist: " + artist)
              }
            }

            //alert(JSON.stringify(result))
            return result                         
          }
        },
        filteredArtistsContains() {
          //alert("filteredArtistsContains")
          //alert(this.selType)
          if(this.selType!='') {
            //alert("1")
            var lookup = {};
            var items = Jukebox.filter(
              (jukebox) =>            
              jukebox.Languages == this.selLanguage 
              && jukebox.Genres == this.selGenre           
              && jukebox.Subgenres == this.selSubgenre 
              //&& jukebox.Artists == this.selArtist 
              //&& jukebox.Albums == this.selAlbum
              //&& jukebox.Songs[0].Title == '01_Adoro_Bronco_Adios Disco 1.mp3'  
              && jukebox.Artists.toLowerCase()  
              .includes(this.Artist.trim().toLowerCase())                           
            );
            var result = [];

            for (var item, i = 0; item = items[i++];) {
              var artist = item.Artists;

              if (!(artist in lookup)) {
                lookup[artist] = 1;
                //alert("lookup: " + JSON.stringify(lookup))
                result.push(artist);
                
              }
              else {
                //alert("artist: " + artist)
              }
            }

            //alert(JSON.stringify(result))
            return result                             
          } else {
            //alert("2")
            //alert("1: "+ this.jukebox)
            //return this.jukebox = []
            //alert("2: "+ this.jukebox)
             
          }   
        },
        filteredYouTubePlayListContains(genres) {
          //alert("filteredYouTubePlayListContains")   
          //alert(JSON.stringify(genres.genre))
          for( var i = 0; i < genres.length; i++){ 
            //alert(genres[i].genre)
            return this.youtubeplaylistContains(
              (youtubeplaylistContains) =>                                     
              youtubeplaylistContains.genre.toLowerCase() == genres[i].genre.toLowerCase() &&
                youtubes.artist 
                .includes(this.Artist.toLowerCase().trim().toLowerCase())  
            );
          }                                     
        },
        filteredYouTubes() {
          //alert("filteredYouTubes")   
          //alert("youtubes.genres: " + this.youtubes.genres[0].genre) 
          //alert("0: this.selLanguage: " + this.selLanguage)             
          //alert("0: this.selGenre: " + this.selGenre)  
          //this.selGenre = 'Cuco'
          //alert("2: this.selLanguage: " + this.selLanguage) 
          if(this.selType!='') {  
            //alert("this.selType: " + this.selType)             
            if(this.selLanguage!="Members" && this.selGenre=="") {
              //alert("1: this.selLanguage: " + this.selLanguage) 
              return this.youtubes.filter(
                (youtubes) =>                                     
                youtubes.language == this.selLanguage &&
                youtubes.artist 
                .includes(this.Artist.toLowerCase().trim().toLowerCase())     
              );                             
            } else if(this.selLanguage!="Members" && this.selGenre!="") {              
              //alert("2: this.selLanguage: " + this.selLanguage) 
              return this.youtubes.filter(
                (youtubes) =>                                     
                youtubes.language == this.selLanguage &&
                youtubes.genre.includes(this.selGenre) &&     
                youtubes.artist.trim().toLowerCase().includes(this.Artist.trim().toLowerCase()) 
              );
            }  else if(this.selLanguage=="Members" && this.selGenre!="") {                             
              if(this.selSearchBy == 'Artist') {
                //alert("3: this.selSearchBy: Artist")
                return this.youtubes.filter(
                  (youtubes) =>                                     
                  youtubes.genre.includes(this.selGenre) &&  
                  youtubes.artist.trim().toLowerCase().includes(this.Artist.trim().toLowerCase())                                          
                );                          
              } else if(this.selSearchBy == 'Title') {
                //alert("3: this.selSearchBy: Title")
                return this.youtubes.filter(
                  (youtubes) =>                                     
                  youtubes.genre.includes(this.selGenre) &&  
                  youtubes.title.trim().toLowerCase().includes(this.Title.trim().toLowerCase()) 
                                         
                );   
              }
            }            
            else {
              //alert("4: this.selLanguage: " + this.selLanguage)  
              if(this.selSearchBy == 'Artist') {  
                return this.youtubes.filter(
                  (youtubes) =>                                                    
                  youtubes.artist.trim().toLowerCase().includes(this.Artist.trim().toLowerCase())     
                );     
              } else if(this.selSearchBy == 'Title') {
                return this.youtubes.filter(
                  (youtubes) =>                                                    
                  youtubes.title.trim().toLowerCase().includes(this.Title.trim().toLowerCase())     
                );
              }     
            }
          }
        },
        filteredAlbumsStartsWith() {              
          //alert("filteredAlbumsStartsWith")                
          //alert(this.selType)
          //if(this.selType!='') {
            //if(this.selLanguage != '' && this.selGenre != '' && this.selSubgenre != '' && this.selArtist != '' && this.selArtist != null ) {  
              //alert(this.selType+"/"+this.selLanguage+"/"+this.selGenre+"/"+this.selSubgenre+"/"+this.selArtist)          
              //alert(this.selGenre)
              //alert(this.selSubgenre)
              //alert(this.selArtist )

              return this.jukebox.filter(
                (jukebox) =>            
                jukebox.Types == this.selType
                && jukebox.Languages == this.selLanguage 
                && jukebox.Genres == this.selGenre           
                && jukebox.Subgenres == this.selSubgenre 
                && jukebox.Artists == this.selArtist 
                &&
                jukebox.Albums.toLowerCase()  
                .includes(this.Artist.trim().toLowerCase())                           
              );            
            //}
          //}                           
        },
        filteredSongsStartsWith() {   
           
          //alert("filteredSongsStartsWith")                
          //alert(this.selType)
          //alert(this.selLanguage)          
              //alert(this.selGenre)
              //alert(this.selSubgenre)
              //alert(this.selArtist)
              //alert(this.selAlbum)
              //alert("this.selSong: " + this.selSong)
          
          if(this.selType!='') {
            if(this.selLanguage != '' && this.selGenre != '' && this.selSubgenre != '' && this.selArtist != '' && this.selArtist != null && this.selAlbum != '' && this.selAlbum != null ) {  
              

              return this.jukebox.filter(
                (jukebox) =>            
                jukebox.Languages == this.selLanguage 
                && jukebox.Genres == this.selGenre           
                && jukebox.Subgenres == this.selSubgenre 
                && jukebox.Artists == this.selArtist 
                && jukebox.Albums == this.selAlbum
                //&& jukebox.Songs[0].Title == '01_Adoro_Bronco_Adios Disco 1.mp3'  
                //&&
                //jukebox.Artists.toLowerCase()  
                //.startsWith(this.Artist.trim().toLowerCase())                           
              );            
            }
          }                           
        },
        filteredbyPlaylist() {
          //alert("filteredbyGenrePlaylist")
          //alert(this.selType)
          if(this.playlistSearch!='') {
            return this.jukebox.filter(
              (jukebox) =>            
              //jukebox.Types == this.selType
              jukebox.Languages == "Spanish"
              
              && jukebox.Genres.includes("Mexicana")
              //&& (
              //   //jukebox.Subgenres.includes("Ranchera") ||
              //   jukebox.Subgenres.includes("Bolero") 
              //  //jukebox.Subgenres.includes("Balada")
              //)         
              
              //|| jukebox.Subgenres == "Cumbia"         
              //&& jukebox.Subgenres == this.selSubgenre              
              && jukebox.Artists == 'Vicente Fernandez'
              &&
              jukebox.Subgenres
              .includes(this.playlistSearch)                           
            );
          }
        },
        filteredMember() {
          //alert("this.selectedMember: " + this.selectedMember)
          return this.members = []
          //  (members) =>            
          //  members.id == this.selectedMember                    
          //);
        },  
        filteredGenrePlaylist() {
          //alert("filteredGenrePlaylist")
          //alert(this.selType)
          
          return this.masterPlaylist.filter(
            (masterPlaylist) =>            
            masterPlaylist.Genres == this.selGenre
          );          
        },
        filteredGenrePlaylistSongs() {
          //alert("filteredGenrePlaylistSongs")
          //alert(this.selPlaylist)
          
          return this.masterPlaylist.filter(
            (masterPlaylist) =>            
            masterPlaylist.Playlist == this.selPlaylist
          );          
        },                
      },
    el: '#app',
    props: ['document'],
    setup() {
      //const { error, documents } = getCollection('test')
      //const ep = getDocument('test', 'artist', 'Elvis Presley');
      
      let documents = []
      //documents.forEach((doc) => {
      //    console.log(documents.id, documents.data());
      //});
      //console.log(documents.data())
      //if(documents!= null) {
        //console.log(documents)
        //console.log(documents['rawValue'])
      //}

      const db = getFirestore();

      async function getCollectionData() {
        const collectionRef = collection(db, "test");
        const snapshot = await getDocs(collectionRef);

        snapshot.forEach((doc) => {
          //console.log(doc.data());
          documents.push(doc.data())                         
        });
      }      

      var artists = []
      //setTimeout(function() {
        //console.log(documents)
        getCollectionData()        
      //}
      //,3000);

      setTimeout(function() {
        for(var i=0;i<documents.length;i++) {
          //artists.push(documents[i].artist)
          //console.log(artists)
          //console.log("sorted: "+documents.sort((a, b) => a.artist.localeCompare(b.artist)))
        }  
        //console.log("normal: "+documents)
        //documents.sort((a, b) => a.artist.localeCompare(b.artist))
        //console.log("sorted: "+documents.sort((a, b) => a.artist.localeCompare(b.artist)))
      }
      ,10000);
      
      let title = ref('Elvis Lives')
      let artist = ref('Elvis')
      let newDocs = ref(documents)

      const id = ref(null)
      //let showForm = ref(false)
      const createPlaylist = ref('false')    

      const { user } = getUser()  
      let rootpath = rootPath
      const toast = useToast()    
      
      const jukebox = ref(Jukebox) 
      const empty = ref(Empty)
      const types = ref(Types)
      let selSearchByString = ref('StartsWith')    
      
      let selectedMember = ref(0)
      let selectedType = ref(0)
      let selectedLanguage = ref(0)
      let selectedGenre = ref(0)
      let selectedSubgenre = ref(0)
      let selectedArtist = ref(0)
      let selectedAlbum = ref()
      let selectedSong = ref(0)
      let selectedPlaylist = ref(0)      
      
      let selYTLanguage = ref("English")
      let selYTGenre = ref("")

      let currentUser = ref([0])
      let curUser = ref('')

      let curArtist = ref('Bronco')
      
      let prevTime = ref(4)
      let active = ref(0) 

      const selMember = ref('')
      let selType = ref('Albums')
      //alert(Jukebox[0].Languages)
      const selLanguage = ref('Spanish')
      const selGenre = ref('Grupos')
      const selSubgenre = ref('Romance')
      const selArtist = ref('')
      const selArtists = ref('')
      const selAlbum = ref('')
      const selSong = ref('')
      const selPlaylist = ref('')

      const youtubeLinks = ref(YouTubeLinks)
      const youtubeplaylists = ref(Youtubeplaylists)
      const youtubes = ref(Youtubes)

      const masterPlaylist = ref(Playlist)
      const requests = ref(Requests)
      const splGenres = ref(SPLGenres)
      const eplGenres = ref(EPLGenres)
      const curPLLanguage = ref('Spanish')

      const members = ref(Members)

      const dj = ref(DJ)
      const alpha = ref(Alpha)

      let typeVisited = 0
      let langVisited = 0
      let genreVisited = 0
      let subgenreVisited = 0
      let artistVisited = 0
      let albumVisited = 0
      let songVisited = 0  
      
      let curLevel = ref("Type")
      let playlistSearch = ref('')
      let selSearchBy = ref('Artist')

      const colorArray = [
        "'#d50000','#ff5252','#b71c1c','#d32f2f','#f44336','#e57373','#ffcdd2'",
        "'#c51162','#ff4081','#880e4f','#c2185b','#e91e63','#f06292','#f8bbd0'",
        "'#aa00ff','#e040fb','#4a148c','#7b1fa2','#9c27b0','#ba68c8','#e1bee7'",
        "'#6200ea','#7c4dff','#311b92','#512da8','#673ab7','#9575cd','#d1c4e9'",
        "'#00bfa5','#64ffda','#004d40','#00796b','#009688','#4db6ac','#b2dfdb'",
        "'#ffd600','#ffff00','#f57f17','#fbc02d','#ffeb3b','#fff176',''#fff9c4",
        "'#ff6d00','#ffab40','#e65100','#f57c00','#ff9800','#ffb74d','#ffb74d'",
      ]

      //alert(colorArray[1])
      var curColorIndex = $("#colorArrayIndex").html()
      var curColorArray = colorArray[curColorIndex].split(',')       

      var curTypeColor = curColorArray[0].replace('\'','').replace('\'','')
      var curLanguageColor = curColorArray[1].replace('\'','').replace('\'','')
      var curGenreColor = curColorArray[2].replace('\'','').replace('\'','')
      var curSubgenreColor = curColorArray[3].replace('\'','').replace('\'','')
      var curArtistColor = curColorArray[4].replace('\'','').replace('\'','')
      var curAlbumColor = curColorArray[5].replace('\'','').replace('\'','')

      //alert(curTypeColor)

      var curFontColor = 'white'      

      ////console.log("playlistSearch: " + playlistSearch.value)

      //alert("DJ : " + JSON.stringify(dj));
      //alert("Alpha : " + JSON.stringify(Alpha));
      //alert("A : " + JSON.stringify(Alpha[0]));

      const thumbsSwiper = ref(null);

      const setThumbsSwiper = (swiper) => {
          //alert("swiper: " + swiper.value)
          ////console.log("setThumbsSwiper");
          thumbsSwiper.value = swiper;           
      };

      //$("#inpPlaylistSearchId").val('playlistSearch')

      //alert("user.value.email: " + user.value.email)

      /*
      if(user.value.email=='mario@google.com') {
        curUser.value = 'Mario'        
      } else if(user.value.email=='luis@google.com') {
        curUser.value = 'Luis'
      } else {
        curUser.value = user.value.displayName
      }
      */

      curUser.value = user.value.displayName
      //alert("curUser.value: " + curUser.value)''

      const showColors =  () => { 
        alert("showColors")        
        $("#divColors").toggle();        
      }

      const setColorArray =  (colorArrayIndex) => { 
        //alert(colorArray[colorArrayIndex])
        $("#colorArrayIndex").html(colorArrayIndex)

        var curColorArray = colorArray[colorArrayIndex].split(',')

        var curTypeColor = curColorArray[0].replace('\'','').replace('\'','')
        var curLanguageColor = curColorArray[1].replace('\'','').replace('\'','')
        var curGenreColor = curColorArray[2].replace('\'','').replace('\'','')
        var curSubgenreColor = curColorArray[3].replace('\'','').replace('\'','')
        var curArtistColor = curColorArray[4].replace('\'','').replace('\'','')
        var curAlbumColor = curColorArray[5].replace('\'','').replace('\'','')

        //alert(curTypeColor)

        $(".btnType").css("background-color", curTypeColor)
        $(".btnLanguage").css("background-color", curLanguageColor)
        $(".btnGenre").css("background-color", curGenreColor)
        $(".btnSubgenre").css("background-color", curSubgenreColor)
        $(".btnArtist").css("background-color", curArtistColor)
        $(".btnAlbum").css("background-color", curAlbumColor)

        curFontColor = "white"
        
        if(colorArrayIndex==5){
          //curFontColor = "black"
        } 

        $(".btnType").css("color", curFontColor)
        $(".btnLanguage").css("color", curFontColor)
        $(".btnGenre").css("color", curFontColor)
        $(".btnSubgenre").css("color", curFontColor)
        $(".btnArtist").css("color", "black")
        $(".btnAlbum").css("color", "black")
      }

      const uniqueArray =  (thisArray) => { 
        if(thisArray) {
            let tempArray = [];
            for (let item of thisArray) {
              (tempItem => {
                //alert("item: "+item)
                if (!(tempItem.length > 0 && tempItem.find(x => x.artist === item.artist))) tempArray.push(item);
                //alert("tempArray: "+tempArray)
              })(tempArray.filter(x => x.artist === item.artist))
            }
            return tempArray;
          }
      }

      const showKB =  () => { 
        //alert("showKB")
        $("#divKB").removeClass("hidden") 
      }

      const scrollAlbums = (type,language,genre,subgenre,artist,album) => { 
        //alert("type: "+type)
        //alert("language: "+language)
        //alert("genre: "+genre)
        //alert("subgenre: "+subgenre)
        //alert("artist: "+artist)
        //alert("album: "+album)
        
        //document.getElementById("divAlbums").scrollBy(300, 100);
        selType.value=type
        selLanguage.value=language
        selGenre.value=genre
        selSubgenre.value=subgenre
        selArtist.value=artist
        selAlbum.value=album
        
        $("#divFooter").hide() 
        $(".divDancers").hide()
        $(".divSaturday").hide()
        $("#divAlbums").hide() 
        $("#btnBack").hide() 
        $("#divGenres").hide()  
        $("#divSubgenres").hide()  
        $("#divArtists").hide()  
        $("#divAlbums").hide()   
        $("#divSongs").show()          
      }

      const blur = (el) => {          
        document.getElementById(el).value = ""
        $("#"+el).css('background-color','white')
      }

      const setSearchBy = async (searchBy) => {                
        //alert("1: searchBy: " + searchBy)
        selSearchBy.value = searchBy
        //alert("2: selSearchBy: " + selSearchBy.value)
        if(searchBy=='Artist') {
          $("#inpSearchArtist").css('background-color','#FFFFE0')
        } else if(searchBy=='Title') {
          $("#inpSearchTitle").css('background-color','#FFFFE0')
        }
      }

      const setPreview = async (seconds) => {        
        prevTime.value = seconds
        //alert("prevTime: " + prevTime.value)
      }

      const addToDJ = async (i,selType,selLanguage,selGenre,selSubgenre,selArtist,selAlbum,song,songs) => {
        var songPath = selType+"/"+selLanguage+"/"+selGenre+"/"+selSubgenre+"/"+selArtist+"/"+selAlbum+"/"+song.Title;
        //alert("songPath: "+ songPath)
        //listen.setAttribute( 'src', songPath)
        //play(rootpath+"/"+songPath)
        //Add the code to push the trak to the DJ.json file
        window.location.href = "http://localhost:8100?addDJ="+songPath;
      }

      const showLanguages = (i,type) => {  
        //alert("type: " + type)    
        selectedType.value = i
        selType.value = type
        selArtists.value = ""             

        curLevel.value = "Language" 

        //$(".divContainer").hide()          
        
        $(".divLogo").show()  
        $(".divTheJB").hide()          
        //$(".divDancers").hide()  
        $("#divAlbums").show()
        $(".divSaturday").hide()  

        $(".divLanguages").show()  
        $("#lblCollectionType").html("Languages")
        //$("#btnBack").show() 
        //$("#divPreview").show() 
        //$("#divSearch").show() 
        //$("#divFooter").show()               
      }

      const showGenres = (i,language,documents,types) => { 
        //console.log("documents: "+JSON.stringify(documents))
        //alert("Type: "+selType.value)
        //alert("Language: "+language)
        //selArtists = document.getElementById("divSelectedArtists").innerHTML
          
        selectedLanguage.value = i
        selLanguage.value = language
        selArtists.value = ""             
        
        selGenre.value = ''
        selSubgenre.value = ''
        selArtist.value = ''        
        selAlbum.value = ''        

        curLevel.value = "Genre" 
        $("#divAlbums").show()
        $(".divDancers").hide()
        $(".divSaturday").hide()          
                  
        //$("#divFooter").show()                 
        //$("#btnBack").show() 

        if(selType.value == "Singles") {
          selGenre.value = 'Various'
          selSubgenre.value = 'Variety'
          selAlbum.value = 'Mix'
                    
          if(selLanguage.value=='English') {
            //alert("selType: "+selType.value)
            //alert("selLanguage: "+selLanguage.value)
            //curLevel.value = "Artists"
            $("#divArtists").show()
            $("#divAlbums").show()
            $(".divSaturday").hide() 
            $(".divDancers").hide()  
            $(".divSubgenres").hide()             
          } else {
            curLevel.value = "Member Playlists"
            $(".divSaturday").hide()  
            $("#divAlbums").show()
            $("#divSongs").show()
          }
        } else {
          $("#divAlbums").show()
          $("#divSongs").hide()
          $(".divGenres").show()
          $(".divSubgenres").hide()
        } 
        
        $("#lblCollectionType").html("Genres")

        var artists = JSON.parse(document.getElementById("divSelectedArtists").innerHTML)
        newDocs = artists
        //alert(types.length)
        for(var i=0;i<types.length;i++) {
          //$("#divSelectedTypes").append('<div style="display: flex;margin- auto;border: solid black 1px" class="w-full"><input id="chkType""'+i+'" type="checkbox" class="w3-check" checked/><input id="btnType"'+i+'" type="button" class="w3-btn w3-blue" style="margin: 3px;text-align: left" value="'+types[i].Type+'" /></div>')          
          for(var j=0;j<types[i].Languages.length;j++) {
            //$("#divSelectedLanguages").append('<div style="display: flex;margin- auto;border: solid black 1px" class="w-full"><input id="chkLanguages""'+j+'" type="checkbox" class="w3-check" checked/><input id="btnLanguage"'+j+'" type="button" class="w3-btn w3-green" style="margin: 3px;text-align: left" value="'+types[i].Languages[j].Language+'" /></div>')          
          }
        }

        //$("#accJukebox").append().empty()

        var typeArray = []
        var languageArray = []
        var x=0
        var curArtists = []
        var tempArray = []

        for(var i=0;i<(types.length-3);i++) {
          typeArray.push(types[i].Type)
          for(var j=0;j<types[i].Languages.length;j++) {
            languageArray.push(types[i].Languages[j].Language)
          }
        }



          /*

                    <div id="divSearchBy" style="margin-bottom: 0px" class="w-26rem hidden">
            <multiselect v-model="value" :options="getUniques" :multiple="true" :close-on-select="false" :clear-on-select="true"
                        :preserve-search="true" placeholder="Select some Artists" label="artist" track-by="album" :preselect-first="false">
              <template #selection="{ values, isOpen }">
                <span class="multiselect__single"
                      v-if="values.length"
                      v-show="!isOpen">{{ values.length }} Artists selected</span>
              </template>
            </multiselect>
          </div>                
        </div>

        
`        $("#accJukebox").append().empty()
        for(var i=0;i<(types.length-2);i++) {          
          var curType = types[i].Type
          //var previd = "accJukebox"
          //var id = "divType"+curType
          //var target = "divType"+curType+i

          $("#accJukebox").append(`         
            <div id="divTypes" style="background-color: white" class="accordion-collapse collapse show" data-bs-parent="#accJukebox">
              <button style="text-align: left;margin-left: 0px;margin-top: 2px;margin-bottom: 2px;font-size: 20px;border-radius: 8px;border: solid black 1px;color: white;background-color: #0066b2;width: 390px" class="w3-button" type="button" 
                data-bs-toggle="collapse" data-bs-target="#divTypes`+curType+`" 
                aria-expanded="true" aria-controls="divTypes"`+curType+`">              
                  `+curType+`
              </button>
              <div id="divTypes`+curType+`" style="background-color: white" class="accordion-collapse collapse" data-bs-parent="#divTypes">
            `)
            for(var j=0;j<types[i].Languages.length;j++) {          
              var curLanguage = types[i].Languages[j].Language
              //previd = id
              //id = "divLanguage"+curType+curLanguage
              //target = "divLanguage"+curType+curLanguage+i+j

              $("#divTypes"+curType).append(`         
                <button style="text-align: left;margin-left: 10px;margin-top: 2px;margin-bottom: 2px;font-size: 20px;border-radius: 8px;border: solid black 1px;color: white;background-color: #4B9CD3;width: 380px" class="w3-button" type="button" 
                  data-bs-toggle="collapse" data-bs-target="#divTypes`+curType+curLanguage+`" 
                  aria-expanded="true" aria-controls="divTypes`+curType+curLanguage+`">              
                    `+curLanguage+`
                </button>
                <div id="divTypes`+curType+curLanguage+`" style="background-color: white" class="accordion-collapse collapse" data-bs-parent="#divTypes`+curType+`">                                                                                                                          
              `)
              for(var k=0;k<types[i].Languages[j].Genres.length;k++) {          
                var curGenre = types[i].Languages[j].Genres[k].Genre.replace(' ','').replace(' ','')
                //previd = id
                //id = "divLanguage"+curType+curLanguage
                //target = "divLanguage"+curType+curLanguage+i+j

                var curDisplayGenre = ''
                  if(curGenre == "RockenEspanol") {
                    curDisplayGenre = "Rock en Espanol"
                  } else {
                    curDisplayGenre = curGenre
                  }

                $("#divTypes"+curType+curLanguage).append(`         
                  <button style="text-align: left;margin-left: 20px;margin-top: 2px;margin-bottom: 2px;font-size: 20px;border-radius: 8px;border: solid black 1px;color: white;background-color: #72A0C1;width: 370px" class="w3-button" type="button" 
                    data-bs-toggle="collapse" data-bs-target="#divTypes`+curType+curLanguage+curGenre+`" 
                    aria-expanded="true" aria-controls="divTypes`+curType+curLanguage+curGenre+`">              
                      `+curDisplayGenre+`
                  </button>
                  <div id="divTypes`+curType+curLanguage+curGenre+`" style="background-color: white" class="accordion-collapse collapse" data-bs-parent="#divTypes`+curType+curLanguage+`">                                                                                                                          
                `)
                for(var l=0;l<types[i].Languages[j].Genres[k].Subgenres.length;l++) {          
                  var curSubgenre = types[i].Languages[j].Genres[k].Subgenres[l].Subgenre
                  //previd = id
                  //id = "divLanguage"+curType+curLanguage
                  //target = "divLanguage"+curType+curLanguage+i+j

                  $("#divTypes"+curType+curLanguage+curGenre).append(`         
                    <button style="text-align: left;margin-left: 30px;margin-top: 2px;margin-bottom: 2px;font-size: 20px;border-radius: 8px;border: solid black 1px;color: blsck;background-color: #B9D9EB;width: 360px" class="w3-button" type="button" 
                      data-bs-toggle="collapse" data-bs-target="#divTypes`+curType+curLanguage+curGenre+curSubgenre+`" 
                      aria-expanded="true" aria-controls="divTypes`+curType+curLanguage+curGenre+curSubgenre+`">              
                        `+curSubgenre+`
                    </button>
                    <div id="divTypes`+curType+curLanguage+curGenre+curSubgenre+`" style="background-color: white" class="accordion-collapse collapse" data-bs-parent="#divTypes`+curType+curLanguage+curGenre+`">                                                                                                                          
                  `)
                }
              }
            }
          `</div></div></div></div>`            
        }
        */                  
      }

      const showSubgenres = (i,type) => {
        //alert("showSubgenres")
        curLevel.value = "Subgenre"  
        selectedGenre.value = i
        selGenre.value = type
        selArtists.value = ""             

        //$(".divContainer").hide()           
        $(".divSaturday").hide()           
        //$("#btnBack").show() 

        //alert("Show Subgenres")
        $("#divSubgenres").css('max-height','600px')
        $(".divSubgenres").show()
        
        $(".divRockola").hide()   
        $("#lblCollectionType").html("Subgenres")
      }

      const showArtists = (i,type,documents,types ) => {
      }

      const showArtists12112024 = (i,type,documents,types ) => {
        //curLevel.value = "Artist"  
       // alert("showArtists i: " + i)    
        
        //selectedSubgenre.value = i
        selSubgenre.value = type
        //alert("selectedSubgenre: " + selectedSubgenre.value)
        //alert("selSubgenre: " + selSubgenre.value)
        
        //$(".divContainer").hide()                        
        //$("#btnBack").show() 

        var albumCount = -1

        //documents = documents.sort()
        documents.sort((a, b) => a.artist.localeCompare(b.artist))      

        for(var i=0;i<documents.length;i++) {
          console.log(JSON.stringify(documents[i].artist)) 
          
          var curType = documents[i].type
          var curLanguage = documents[i].language
          var curGenre = documents[i].genre
          var curSubgenre = documents[i].subgenre
          var curArtist = documents[i].artist
          var curAlbum = documents[i].album

          var curSArtist = documents[i].album
          var curSAlbum = documents[i].artist

          var curArtist = documents[i].artist
          var curAlbum = documents[i].album

          var curDisplayArtist = documents[i].artist
          var curDisplayAlbum = documents[i].album

          var curTypeId = "divPath"+documents[i].type.replace(/ /g, '')
          var curLanguageId = curTypeId+documents[i].language.replace(/ /g, '')
          var curGenreId = curLanguageId+documents[i].genre.replace(/ /g, '')
          var curSubgenreId = curGenreId+documents[i].subgenre.replace(/ /g, '')
          var curArtistId = curSubgenreId+documents[i].artist.replace('...',' ').replace('!','').replace('[','').replace(']','').replace('(','').replace(')','').replace('\'','').replace('.','').replace('-','').replace('(US)','').replace('&','').replace('(BASS BOOST)','').replace('$','').replace('.','').replace(/ /g, '')
          var curAlbumId = curArtistId+documents[i].album.replace('...',' ').replace('!','').replace('[','').replace(']','').replace('(','').replace(')','').replace('\'','').replace('.','').replace('-','').replace('(US)','').replace('&','').replace('(BASS BOOST)','').replace('$','').replace('.','').replace(/ /g, '')

          var curSArtistId = curSubgenreId+documents[i].album.replace('...',' ').replace('!','').replace('[','').replace(']','').replace('(','').replace(')','').replace('\'','').replace('.','').replace('-','').replace('(US)','').replace('&','').replace('(BASS BOOST)','').replace('$','').replace('.','').replace(/ /g, '')
          var curSAlbumId = curSArtistId+documents[i].artist.replace('...',' ').replace('!','').replace('[','').replace(']','').replace('(','').replace(')','').replace('\'','').replace('.','').replace('-','').replace('(US)','').replace('&','').replace('(BASS BOOST)','').replace('$','').replace('.','').replace(/ /g, '')
          
          if(curType=="Singles") {
            //curArtistId == curAlbumId+documents[i].album.replace('...',' ').replace('!','').replace('[','').replace(']','').replace('(','').replace(')','').replace('\'','').replace('.','').replace('-','').replace('(US)','').replace('&','').replace('(BASS BOOST)','').replace('$','').replace('.','')
            //curAlbumId ==curAlbumId+documents[i].artist.replace('...',' ').replace('!','').replace('[','').replace(']','').replace('(','').replace(')','').replace('\'','').replace('.','').replace('-','').replace('(US)','').replace('&','').replace('(BASS BOOST)','').replace('$','').replace('.','')
          }

          //if(curTypeId=="divPathAlbums") {
            albumCount++
            //if(albumCount<2) {
              // add Type if it does not already exist
              /*
              if($("#"+curTypeId).length) {
                //alert("curTypeId: "+curTypeId+": Exists")
              } else {
                curFontColor = "white"
                $("#accJukebox").append(`         
                <div id="`+curTypeId+`" style="background-color: white" class="accordion-collapse collapse show 
                  type">
                  <button id="btn`+curTypeId+`" style="text-align: left;margin-left: 0px;
                    margin-top: 2px;margin-bottom: 2px;font-size: 20px;border-radius: 8px;border: solid black 1px;
                    color: `+curFontColor+`;background-color: `+curTypeColor+`;width: 360px" 
                    class="w3-button btnType" type="button">              
                      `+curType+`
                  </button>                  
                </div>
                `)
                const element = document.getElementById("btn"+curTypeId);
                element.addEventListener("click", function() {
                  curType = element.innerHTML.trim()
                  
                  selType.value = curType
                  selLanguage.value = ''                  
                  selGenre.value = ''                  
                  selSubgenre.value = ''                  
                  selArtist.value = ''                  
                  selAlbum.value = ''                  

                  $(".language").hide()
                  $("."+curType).toggle()
                });
              } 

              curFontColor = "white"
              // add Language if it does not already exist
              if($("#"+curLanguageId).length) {
                //alert("curTypeId: "+curTypeId+": Exists")
              } else {
                $("#"+curTypeId).append(`                         
                <div id="`+curLanguageId+`" style="background-color: white" class="accordion-collapse collapse 
                  `+curType+` language">                  
                  <button id="btn`+curLanguageId+`" style="text-align: left;margin-left: 5px;
                    margin-top: 2px;margin-bottom: 2px;font-size: 20px;border-radius: 8px;border: solid black 1px;
                    color: `+curFontColor+`;background-color: `+curLanguageColor+`;width: 355px" 
                    class="w3-button btnType" type="button">              
                  `+curLanguage+`
                  </button>                  
                </div>
                `)
                
                const element = document.getElementById("btn"+curLanguageId);
                element.addEventListener("click", function() {
                  curLanguage = element.innerHTML.trim()
                  selLanguage.value = curLanguage      
                  selGenre.value = ''                  
                  selSubgenre.value = ''                  
                  selArtist.value = ''                  
                  selAlbum.value = ''                              
                  $(".genre").hide()
                  $("."+curLanguage).toggle()
                });
                
              }
              
              // add Genre if it does not already exist
              if($("#"+curGenreId).length) {
                //alert("curTypeId: "+curTypeId+": Exists")
              } else {
                $("#"+curLanguageId).append(`                         
                <div id="`+curGenreId+`" style="background-color: white" class="accordion-collapse collapse 
                `+curLanguage+` genre">                  
                  <button id="btn`+curGenreId+`" style="text-align: left;margin-left: 10px;
                    margin-top: 2px;margin-bottom: 2px;font-size: 20px;border-radius: 8px;border: solid black 1px;
                    color: `+curFontColor+`;background-color: `+curGenreColor+`;width: 350px" 
                    class="w3-button btnGenre" type="button">              
                  `+curGenre+`
                  </button>                  
                </div>
                `)
                const element = document.getElementById("btn"+curGenreId);
                element.addEventListener("click", function() {
                  curGenre = element.innerHTML.trim()
                  selGenre.value = curGenre            
                  selSubgenre.value = ''                  
                  selArtist.value = ''                  
                  selAlbum.value = ''                                     
                  $(".subgenre").hide()
                  $("."+curGenre).toggle()
                });
              }

              // add Subgenre if it does not already exist
              if($("#"+curSubgenreId).length) {
                //alert("curTypeId: "+curTypeId+": Exists")
              } else {
                $("#"+curGenreId).append(`                         
                <div id="`+curSubgenreId+`" style="background-color: white" class="accordion-collapse 
                  collapse `+curGenre+` subgenre">                  
                  <button id="btn`+curSubgenreId+`" style="text-align: left;
                    margin-left: 15px;margin-top: 2px;margin-bottom: 2px;font-size: 20px;
                    border-radius: 8px;border: solid black 1px;color: `+curFontColor+`;
                    background-color: `+curSubgenreColor+`;width: 345px" 
                    class="w3-button btnSubgenre" type="button">              
                     `+curSubgenre+`
                  </button>                  
                </div>
                `)
                const element = document.getElementById("btn"+curSubgenreId);
                element.addEventListener("click", function() {
                  curSubgenre = element.innerHTML.trim()
                  selSubgenre.value = curSubgenre    
                  selArtist.value = ''                  
                  selAlbum.value = ''                                     
              
                  $(".artist").hide()
                  $("."+curSubgenre).toggle()
                });
              }
              */
              if(curType=="Singles") {
                // add Subgenre if it does not already exist
                if($("#"+curSArtistId).length) {
                  //alert("curTypeId: "+curTypeId+": Exists")
                } else {
                  curFontColor = "white"
                  $("#accJukebox").append(`         
                  <div id="`+curSArtistId+`" style="background-color: white" class="accordion-collapse 
                    collapse artist">                  
                    <button id="btn`+curSArtistId+`" style="text-align: left;
                      margin-left: 20px;margin-top: 2px;margin-bottom: 2px;font-size: 20px;
                      border-radius: 8px;border: solid black 1px;color: `+curFontColor+`;
                      background-color: `+curArtistColor+`;width: 340px" 
                      class="w3-button btnArtist" type="button">              
                    `+curSArtist+`
                    </button>                  
                  </div>
                  `)
                  const element = document.getElementById("btn"+curSArtistId);
                  element.addEventListener("click", function() {
                    curArtist = element.innerHTML.trim()
                    selArtist.value = curArtist 
                    selAlbum.value = ''                   
                    $(".album").hide()
                    $("."+curArtist).toggle()
                  });
                }

                // add Subgenre if it does not already exist
                if($("#"+curSAlbumId).length) {
                  //alert("curTypeId: "+curTypeId+": Exists")
                } else {
                  curFontColor = "white"
                  $("#"+curSArtistId).append(`                         
                  <div id="`+curSAlbumId+`" style="background-color: white" class="accordion-collapse 
                    collapse `+curSArtist+` album">                  
                    <button onClick="toggleAlbum('`+curSAlbumId+`')" style="text-align: left;
                      margin-left: 25px;margin-top: 2px;margin-bottom: 2px;font-size: 20px;
                      border-radius: 8px;border: solid black 1px;color: `+curFontColor+`;
                      background-color: `+curAlbumColor+`;width: 335px" 
                      class="w3-button btnArtist" type="button">              
                    `+curSAlbum+`
                    </button>                  
                  </div>
                  `)
                }
              }
              

              if(curType=="Albums") {
                // add Subgenre if it does not already exist
                if($("#"+curArtistId).length) {
                  //alert("curTypeId: "+curTypeId+": Exists")
                } else {
                  curFontColor = "white"
                   $("#accJukebox").append(`                         
                  <div id="`+curArtistId+`" style="background-color: white" class="accordion-collapse 
                    artist">                  
                    <button id="btn`+curArtistId+`" style="text-align: left;
                      margin-left: 20px;margin-top: 2px;margin-bottom: 2px;font-size: 20px;
                      border-radius: 8px;border: solid black 1px;color: `+curFontColor+`;
                      background-color: `+curArtistColor+`;width: 340px" 
                      class="w3-button btnArtist" type="button">              
                    `+curArtist+`
                    </button>                  
                  </div>
                  `)
                  const element = document.getElementById("btn"+curArtistId);
                  element.addEventListener("click", function() {
                    curArtist = element.innerHTML.trim()
                    selArtist.value = curArtist 
                    selAlbum.value = ''                           
                    $(".album").hide()
                    $("."+curArtist.replace(/ /g, '')).toggle()
                  });
                }

                // add Subgenre if it does not already exist
                if($("#"+curAlbumId).length) {
                  //alert("curTypeId: "+curTypeId+": Exists")
                } else {
                  curFontColor = "white"
                  $("#"+curArtistId).append(`                         
                  <div id="`+curAlbumId+`" style="background-color: white" class="accordion-collapse 
                    collapse `+curArtist.replace(' ','').replace(' ','').replace(' ','').replace(' ','').replace(' ','').replace(' ','').replace(' ','').replace(' ','')+` album">                  
                    <button id="btn`+curAlbumId+`" style="text-align: left;
                      margin-left: 25px;margin-top: 2px;margin-bottom: 2px;font-size: 20px;
                      border-radius: 8px;border: solid black 1px;color: `+curFontColor+`;
                      background-color: `+curAlbumColor+`;width: 335px" 
                      class="w3-button btnArtist" type="button">              
                    `+curAlbum+`
                    </button>                  
                  </div>
                  `)
                  //alert("btn"+curAlbumId)
                  //if(curType == "Singles") {
                    const element = document.getElementById("btn"+curAlbumId);
                    element.addEventListener("click", function() {
                      curAlbum = element.innerHTML.trim()
                      selAlbum.value = curAlbum                  
                      //$(".song").hide()
                      $("."+curAlbum.replace(/ /g, '')).toggle()
                    });
                  //}
                }
              }         
            //}            
          //}
          
          
          /*
          if(documents[i].type==selType.value && documents[i].language == selLanguage.value && documents[i].genre == selGenre.value && documents[i].subgenre == selSubgenre.value && documents[i].artist == curArtist ) {
            $("#divTypes"+documents[i].type+documents[i].language+documents[i].genre+documents[i].subgenre+documents[i].artist).append(`
              <button style="text-align: left;margin-left: 50px;margin-top: 2px;margin-bottom: 2px;font-size: 20px;border-radius: 8px;border: solid black 1px;color: black;
                background-color: #33885B;width: 310px" class="w3-button" type="button" 
                data-bs-toggle="collapse" data-bs-target="#divTypes`+curType+curLanguage+curGenre+curSubgenre+curAlbum+`" 
                aria-expanded="true" aria-controls="divTypes`+curType+curLanguage+curGenre+curSubgenre+curArtist+curAlbum+`">              
                  `+curAlbum+`
              </button>              
            `)
          }
          */         
        }

        //$("#divArtists").css('max-height','650px')
        //$("#divArtists").show()
        //$("#divJBSN").hide()
        //$("#divFooter").show()        
        //$("#lblCollectionType").html("Artists")   
        
                //$("#accJukebox").append().empty()
       
        /*
        $("#accJukebox").append().empty()
        for(var i=0;i<(types.length-2);i++) {          
          var curType = types[i].Type
          //var previd = "accJukebox"
          //var id = "divType"+curType
          //var target = "divType"+curType+i

          $("#accJukebox").append(`         
            <div id="divTypes" style="background-color: white" class="accordion-collapse collapse show" data-bs-parent="#accJukebox">
              <button style="text-align: left;margin-left: 0px;margin-top: 2px;margin-bottom: 2px;font-size: 20px;border-radius: 8px;border: solid black 1px;color: white;
                background-color: #0066b2;width: 360px" class="w3-button" type="button" 
                data-bs-toggle="collapse" data-bs-target="#divTypes`+curType+`" 
                aria-expanded="true" aria-controls="divTypes"`+curType+`">              
                  `+curType+`
              </button>
              <div id="divTypes`+curType+`" style="background-color: white" class="accordion-collapse collapse" data-bs-parent="#divTypes">
            `)
            for(var j=0;j<types[i].Languages.length;j++) {          
              var curLanguage = types[i].Languages[j].Language
              //previd = id
              //id = "divLanguage"+curType+curLanguage
              //target = "divLanguage"+curType+curLanguage+i+j

              $("#divTypes"+curType).append(`         
                <button style="text-align: left;margin-left: 10px;margin-top: 2px;margin-bottom: 2px;font-size: 20px;border-radius: 8px;border: solid black 1px;color: white;
                  background-color: #4B9CD3;width: 350px" class="w3-button" type="button" 
                  data-bs-toggle="collapse" data-bs-target="#divTypes`+curType+curLanguage+`" 
                  aria-expanded="true" aria-controls="divTypes`+curType+curLanguage+`">              
                    `+curLanguage+`
                </button>
                <div id="divTypes`+curType+curLanguage+`" style="background-color: white" class="accordion-collapse collapse" data-bs-parent="#divTypes`
                  +curType+`">                                                                                                                          
              `)
              for(var k=0;k<types[i].Languages[j].Genres.length;k++) {          
                var curGenre = types[i].Languages[j].Genres[k].Genre.replace(' ','').replace(' ','')
                //previd = id
                //id = "divLanguage"+curType+curLanguage
                //target = "divLanguage"+curType+curLanguage+i+j

                var curDisplayGenre = ''
                  if(curGenre == "RockenEspanol") {
                    curDisplayGenre = "Rock en Espanol"
                  } else {
                    curDisplayGenre = curGenre
                  }

                $("#divTypes"+curType+curLanguage).append(`         
                  <button style="text-align: left;margin-left: 20px;margin-top: 2px;margin-bottom: 2px;font-size: 20px;border-radius: 8px;border: solid black 1px;color: white;
                    background-color: #72A0C1;width: 340px" class="w3-button" type="button" 
                    data-bs-toggle="collapse" data-bs-target="#divTypes`+curType+curLanguage+curGenre+`" 
                    aria-expanded="true" aria-controls="divTypes`+curType+curLanguage+curGenre+`">              
                      `+curDisplayGenre+`
                  </button>
                  <div id="divTypes`+curType+curLanguage+curGenre+`" style="background-color: white" class="accordion-collapse collapse" data-bs-parent="#divTypes`
                    +curType+curLanguage+`">                                                                                                                          
                `)
                for(var l=0;l<types[i].Languages[j].Genres[k].Subgenres.length;l++) {          
                  var curSubgenre = types[i].Languages[j].Genres[k].Subgenres[l].Subgenre
                  //previd = id
                  //id = "divLanguage"+curType+curLanguage
                  //target = "divLanguage"+curType+curLanguage+i+j
                  $("#divTypes"+curType+curLanguage+curGenre).append(`         
                    <button style="text-align: left;margin-left: 30px;margin-top: 2px;margin-bottom: 2px;font-size: 20px;border-radius: 8px;border: solid black 1px;color: black;
                      background-color: #B9D9EB;width: 330px" class="w3-button" type="button" 
                      data-bs-toggle="collapse" data-bs-target="#divTypes`+curType+curLanguage+curGenre+curSubgenre+`" 
                      aria-expanded="true" aria-controls="divTypes`+curType+curLanguage+curGenre+curSubgenre+`">              
                        `+curSubgenre+`
                    </button>
                    <div id="divTypes`+curType+curLanguage+curGenre+curSubgenre+`" style="background-color: white" class="accordion-collapse collapse" 
                      data-bs-parent="#divTypes`+curType+curLanguage+curGenre+`">                                                                                                                          
                  `)                                      
                }                                
              }
            }
          `</div></div></div></div></div>`            
        }

        var ASGArtists = []
        var AEArtists = []
        var SSArtists = []
        var SEArtists = []
        //alert("divTypes"+selType.value+selLanguage.value+selGenre.value+selSubgenre.value)

        for(var i=0;i<documents.length;i++) {
          var curArtist = documents[i].artist       
          if(documents[i].type==selType.value && documents[i].language == selLanguage.value && documents[i].genre == selGenre.value && documents[i].subgenre == selSubgenre.value ) {
            $("#divTypes"+selType.value+selLanguage.value+selGenre.value+selSubgenre.value).append(`
              <button onClick="addAlbums('divTypes`+selType.value+selLanguage.value+selGenre.value+selSubgenre.value+curArtist.replace(/#| /g, '_')+`','`+curArtist+`')" style="text-align: left;margin-left: 40px;margin-top: 2px;margin-bottom: 2px;font-size: 20px;border-radius: 8px;border: solid black 1px;color: black;
                background-color: #59D9EB;width: 320px" class="w3-button" type="button" 
                data-bs-toggle="collapse" data-bs-target="#divTypes`+documents[i].type+documents[i].language+documents[i].genre+documents[i].subgenre+documents[i].artist+`" 
                aria-expanded="true" aria-controls="divTypes`+documents[i].type+documents[i].language+documents[i].genre+documents[i].subgenre+documents[i].artist+`">              
                  `+curArtist+`
              </button>
              <div id="divTypes`+documents[i].type+documents[i].language+documents[i].genre+documents[i].subgenre+documents[i].artist+`" style="background-color: white" class="accordion-collapse collapse" 
                data-bs-parent="#divTypes`+documents[i].type+documents[i].language+documents[i].genre+documents[i].subgenre+`">  
            `)
          }          
        }
        
        for(var i=0;i<documents.length;i++) {
          var curArtist = documents[i].artist  
          var curAlbum = documents[i].album       
          if(documents[i].type==selType.value && documents[i].language == selLanguage.value && documents[i].genre == selGenre.value && documents[i].subgenre == selSubgenre.value && documents[i].artist == curArtist ) {
            $("#divTypes"+documents[i].type+documents[i].language+documents[i].genre+documents[i].subgenre+documents[i].artist).append(`
              <button style="text-align: left;margin-left: 50px;margin-top: 2px;margin-bottom: 2px;font-size: 20px;border-radius: 8px;border: solid black 1px;color: black;
                background-color: #33885B;width: 310px" class="w3-button" type="button" 
                data-bs-toggle="collapse" data-bs-target="#divTypes`+curType+curLanguage+curGenre+curSubgenre+curAlbum+`" 
                aria-expanded="true" aria-controls="divTypes`+curType+curLanguage+curGenre+curSubgenre+curArtist+curAlbum+`">              
                  `+curAlbum+`
              </button>              
            `)
          }          
        }
        */
      }

      const goBack = () => {          
        //alert("goBack") 
        $(".divContainer").hide()
        $("#divJBSN").show()
        
        //if(selType.value=='Albums') {
          //alert("Back to Albums/Types")
          showTypes()   
        //}
      }    

      const goBackx = (i,type,language,genre,subgenre,artist,album,song) => {          
        //alert(type + "/" + language + "/" + genre + "/" + subgenre + "/" + artist + "/" + album + "/" + song) 
        $(".divContainer").hide()
        if(selType.value=='Albums' && typeVisited==0) {
          typeVisited = 1
          if(langVisited==0) {
            typeVisited = 0
            langVisited = 1
            
            //alert("Back to Albums/Types")
            //selType.value = ''
            showTypes(i,type)                                               
          } else if(genreVisited==0) {
            typeVisited = 0
            langVisited = 0
            genreVisited = 1
            
            //alert("Back to Albums/Languages")
            //selLanguage.value = ''
            showLanguages(1,type)                                               
          } else if(subgenreVisited==0) {
            typeVisited = 0
            langVisited = 0
            genreVisited = 0
            subgenreVisited = 1
            
            //alert("Back to Albums/Genres")
            //selGenre.value = ''
            //selSubgenre.value = ''
            showGenres(i,language)                                               
          } else if(artistVisited==0) {
            typeVisited = 0
            langVisited = 0
            genreVisited = 0
            subgenreVisited = 0
            artistVisited = 1

            //alert("Back to Albums/Subgenres")
            //selArtist.value = ''
            //selGenre.value = ''
            //selSubgenre.value = ''
            showSubgenres(i,genre)                                               
          } else if(type!='Singles' && albumVisited == 0) {
            typeVisited = 0
            langVisited = 0
            genreVisited = 0
            subgenreVisited = 0
            artistVisited = 0
            albumVisited = 1
            
            //alert("Back to Albums/Artists")
            $(".searchArtist").show()
            //selArtist.value = ''            
            showArtists(i,subgenre)                                               
          } else if(song == '') {
            //alert("Back to Albums/Albums")
            //selAlbum.value = ''
            showAlbums(i,artist)                                                         
          } else if(song != '') {
            //alert("Back to Albums/Songs")
            //selSong.value = ''
            showSongs(i,album)    
          } else {
            //alert("Lost")            
          }

        }
        else if(selType.value=='Singles') {
          if(language == '') {
            //alert("Back to Singles/Types")
            showTypes(i,type)                                               
          } else if(artist == '') {
            //alert("Back to Singles/Languages")
            selLanguage.value = ''
            selGenre.value = ''
            selSubgenre.value = ''
            
            //$(".searchArtist").show()
            showLanguages(i,type)                                               
          } else if(album == '') {
            //alert("Back to Singles/Genres")
            selGenre.value = ''
            selSubgenre.value = ''
            selArtist.value = ''
            selAlbum.value = ''
            
            //$(".searchArtist").show()
            showLanguages(i,type)                                               
          }
          else if(song == '') {
            //alert("Back to Singles/Albums")
            
            selArtist.value = '' 
            selAlbum.value = '' 
            selSong.value = '' 
            
            //$(".searchArtist").show()
            showAlbums(i,artist)                                               
          } else { 
            //alert("Back to Singles/Songs")           
                       
            //selArtist.value = ''
            selSong.value = ''
            
            showSongs(i,album)                                               
          }

        } else if(selType.value=='Youtubes') {
          selType.value = ''          
          selLanguage.value = ''          
          selGenre.value = ''          
          selSubgenre.value = ''          
          selArtist.Artist = ''
          selTitle.Artist = ''          
          selAlbum.Album = ''          
          selSong.Song = ''          

          showTypes(0,'Albums') 
        } else if(selType.value=='Playlists') {
          selType.value = ''          
          selLanguage.value = ''          
          selGenre.value = ''          
          selSubgenre.value = ''          
          selArtist.Artist = ''          
          selAlbum.Album = ''          
          selSong.Song = ''          

          showTypes(0,'Albums') 
        }
        else {
          //alert("Really Lost")            
        }         
      }      

      const showTypes = () => {
        curLevel.value = "Types"  

        $(".divContainer").hide() 
        $("#divJBSN").show()
        $("#divTypes").show()
        $("#lblCollectionType").html("Music Collection Type")         
      }        

      const showAlbums = (i,type) => {
        if(selType.value=='Albums') {
          curLevel.value = "Albums"   
        } else {
          curLevel.value = "Artists"   
        }      
        alert("showAlbums")  
        selectedArtist.value = i
        selArtist.value = type
        //alert("selectedArtist: " + selectedArtist.value)        
        //alert("selArtist: " + selArtist.value)        

        //$(".divContainer").hide()
        //$("#divFooter").show() 
        //$("#btnBack").show()              
        
        $("#divAlbums").show()  
        $("#lblCollectionType").html("Albums")           
      }
      
      const showYoutubes = (i,type) => {
        
        //alert("i: "+i)
        //alert("showYoutubes: " + type)
        
        /*
        Find out where it goes after this function call
        */

        selectedType.value = i
        selType.value = type.toString()
        //alert("'"+selType.value+"'")
        //alert("curUser.value: "+curUser.value)        
        
        $(".divContainer").hide()
        $("#divFooter").show() 
        $("#divPreview").hide() 
        $("#btnBack").show()      

        $(".divYTContainer").show() 
        $(".divYoutubes").show() 
        $(".divSearchId").show() 
        $(".divYouTubeContainer").show() 
        $("#lblCollectionType").html("Youtube") 

        var currUser = toTitleCase(curUser.value)
        //alert("currUser: "+ currUser)
        setYT("Members")
      }

      const showSongs = (i,type) => { 
        curLevel.value = "Song"       
        alert("showSongs")  
        selectedAlbum.value = i
        selAlbum.value = type
        //alert("selectedAlbum: " + selectedAlbum.value)        
        //alert("selAlbum: " + selAlbum.value)        

        //$(".divContainer").hide()
        $("#divFooter").hide() 
        $("#btnBack").hide() 
        $("#divGenres").hide()  
        $("#divSubgenres").hide()  
        $("#divArtists").hide()  
        $("#divAlbums").hide()  

        //$("#divSongs").css('max-height','400px')
        $("#divSongs").show()  
        $("#lblCollectionType").html(type)  
                
      }

      const toggleDivRateIt = async () => {   
        //alert($("#chkPreview").is(":checked"))            
        if($("#chkPreview").is(":checked")) { 
          //alert("#chkPreview) true")   
          //$(".divPreviewTimer").show()
        } else {
          //alert("#chkPreview) false")   
          //$(".divPreviewTimer").hide()
        }
      }

      const handlePlaylist = (j,title,songs) => {   
        //alert("handlePlaylist: ")  
        //alert("j: " + j)  
        //alert("title: " + title)  
        //alert("songs: "+ songs)

        let trackNum = 1
        let trackCount = songs.length

        //console.log("trackCount: " + trackCount)
        //console.log("j: " + j)

        var starttime = 7;  // start at 7 seconds
        //alert("prevTime: "+ prevTime.value)       
        var endtime = prevTime.value;    // stop at 5 seconds      
        //console.log("endtime: "+ endtime)  
        $("#divRateIt").show()  
        //alert("1 trackNum: ")              
        if($("#chkPreview").is(":checked")) {
          //alert("2 trackNum: ") 
              //console.log("previewFlag: " + previewFlag)           
            //$("#chkPreview").prop("checked", true);
            //console.log("trackCount: " + trackCount)   
            //console.log("trackNum: " + trackNum)                 
            
            if(trackNum === trackCount) {                        
                trackNum = 0
                //console.log("Reset trackNum: " + trackNum)
            }
            
            //curSong.innerHTML = song.DisplayTitle
            
            listen.setAttribute( 'src', rootpath+title) 
            listen.addEventListener("timeupdate", function() { 
              //console.log("previewFlag: " + previewFlag)
              //console.log("trackCount: " + trackCount)   
              //console.log("trackNum: " + trackNum) 
              //console.log("this.currentTime: " + this.currentTime)   

              if(trackNum === trackCount) {                        
                trackNum = 0
                //console.log("Reset trackNum: " + trackNum)
              }
              $("#chkSong"+trackNum).focus()
              if (this.currentTime >= endtime && $("#chkPreview").is(":checked")) { 
                
                //this.pause();
                //alert("1: trackNum: " + trackNum)                      
                trackNum++; 
                //alert("2: trackNum: " + trackNum)                                                           
                
                ////console.log("trackNum: " + trackNum)

                if($("#chkPLSong"+trackNum).is(":checked")) {
                  //alert("checked")
                } else if(trackNum < trackCount) {
                  //alert("not checked")
                  trackNum++;
                } 

                if(trackNum === trackCount) {                        
                  trackNum = 0
                  //console.log("Reset trackNum: " + trackNum)
                } 
                selectedSong.value = trackNum                    
                
                listen.currentTime = 0;
                //curSong.innerHTML = song[0].DisplayTitle
                //alert(rootpath+songs[trackNum].Title)
                if(songs[trackNum].Title.indexOf('�') ==-1) {
                  listen.setAttribute( 'src', rootpath+songs[trackNum].Title)
                } else {
                  listen.setAttribute( 'src', rootpath+songs[trackNum++].Title)
                }
                ////console.log("Preview Play mode")
                ////console.log("this.currentTime: " + this.currentTime)                     
              }                
            }, false);
        }
        else {                
          //curArtist.value = selArtist //reversed for singles only
          //curAlbum.value =  selAlbum //reversed for singles only

          //playerArtist.value = doc.album
          //trackNumber.value = trackNum
          //curSong.value = song[0].DisplayTitle
                              
          ////console.log("Playing: " +  songs[0].DisplayTitle)
          //alert("Currently Playing: " + rootpath + trackPath) 
          //alert("Title:" +  (rootpath+title))
          listen.setAttribute( 'src', rootpath+title) 
          play(rootpath+title)
          ////console.log("Normal Play mode")                 
          ////console.log("listen.currentTime: " + listen.currentTime) 
          //divArtist.innerHTML = doc.album
          //divAlbum.innerHTML = doc.artist
          //divTrackTitle.innerHTML = song[0].DisplayTitle

          listen.addEventListener("ended", function() {
            trackNum++;                
            

            //alert("trackPath: " + rootpath + trackPath)  
            //console.log("curTrack: " + trackNum)                       
            ////console.log("next Song: " + rootpath + trackPath)
            //curSong.innerHTML = doc.songs[trackNum].Song[0].DisplayTitle

            listen.currentTime = 0;
            
            //listen.setAttribute( 'src', rootpath + trackPath)
            play(title) 
            //console.log("Normal Play mode")                                  
            //console.log("listen.currentTime 2: " + listen.currentTime) 
          });
        }         
      }

      const handleDJ = (i,title) => {   
        //alert("handleDJ: "+i+" "+title)  
        var trackPath =  title
        listen.setAttribute( 'src', rootpath + trackPath) 
        play()
        //alert("i: " + i
      }

      const handleClick = (j, selType, selLanguage, selGenre,selSubgenre,selArtist,selAlbum,song,songs) => {   
                              
        //alert("handleClick: "+j+" "+selType+" "+selLanguage+" "+selGenre+" "+selSubgenre+" "+selArtist+" "+selAlbum+" "+JSON.stringify(song)+" "+JSON.stringify(songs))       
        //alert("songs: " + song.Title)       
        //alert("songs count: " + songs.length)       
        //alert("songs j: " + songs[j].Title)  
        //alert("selAlbum: "+ selAlbum)
        selectedSong.value = j     
        //alert("selectedSong.value: " + selectedSong.value)       

        //alert("song Display title: " + song.DisplayTitle)       
        //alert("doc.songs[index].Song[0].DisplayTitle: " + doc.songs[index].Song[0].DisplayTitle)       
        
        selSong.value = song.Title
        let trackPath = ""
        let trackNum = j    
        //alert("trackPath: " + trackPath)                   

        trackPath = "/" + selType + "/" + selLanguage + "/" + selGenre + "/" + selSubgenre + "/" + selArtist + "/" + selAlbum + "/" + song.Title
        //alert("trackPath: " + trackPath) 
        if(selType == "Singles") {
          trackPath = "/" + selType + "/" + selLanguage + "/" + selGenre + "/" + selSubgenre + "/" + selArtist + "/" + selAlbum + "/" +  song.Title
        }

        //alert("trackPath is : " + trackPath)
        ////console.log("Current Track: " + curTrack);
        //alert("Current Song: " + rootpath + trackPath)
        //alert("previewFlag: " + previewFlag)
        //alert("previewFlag.value: " + previewFlag.value)

        
        let trackCount = songs.length

        ////console.log("trackCount: " + trackCount)
        ////console.log("index: " + index)

        //var starttime = 7;  // start at 7 seconds
        //alert("prevTime: "+ prevTime.value)       
        var endtime = prevTime.value;    // stop at 5 seconds      
        //console.log("endtime: "+ endtime)                 
        if($("#chkPreview").is(":checked")) {
            $(".divPlayer").show()
            ////console.log("previewFlag: " + previewFlag)           
            //$("#chkPreview").prop("checked", true);
            //console.log("trackCount: " + trackCount)   
            //console.log("trackNum: " + trackNum)                 
            
            if(trackNum === trackCount) {                        
                trackNum = 0
                //console.log("Reset trackNum: " + trackNum)
            }

            //curSong.innerHTML = song.DisplayTitle
            
            listen.setAttribute( 'src', rootpath + trackPath) 
            listen.addEventListener("timeupdate", function() { 
              //console.log("previewFlag: " + previewFlag)
              //console.log("trackCount: " + trackCount)   
              //console.log("trackNum: " + trackNum) 
              //console.log("this.currentTime: " + this.currentTime)   

              if(trackNum === trackCount) {                        
                trackNum = 0
                //console.log("Reset trackNum: " + trackNum)
              }
              $("#chkSong"+trackNum).focus()
              if (this.currentTime >= endtime && $("#chkPreview").is(":checked")) {                       
                //this.pause();
                trackNum++;                       
                if($("#chkSong"+trackNum).is(":checked")) {
                  //alert("checked")
                } else if(trackNum < trackCount) {
                  //alert("not checked")
                  trackNum++; 
                  if($("#chkSong"+trackNum).is(":checked")) {
                    //alert("checked")
                  } else if(trackNum < trackCount) {
                    //alert("not checked")
                    trackNum++;                       
                    if($("#chkSong"+trackNum).is(":checked")) {
                      //alert("checked")
                    } else if(trackNum < trackCount) {
                      //alert("not checked")
                      trackNum++;                       
                      if($("#chkSong"+trackNum).is(":checked")) {
                        //alert("checked")
                      } else if(trackNum < trackCount) {
                        //alert("not checked")
                        trackNum++;                       
                      }
                      if($("#chkSong"+trackNum).is(":checked")) {
                        //alert("checked")
                      } else if(trackNum < trackCount) {
                        //alert("not checked")
                        trackNum++;                       
                        if($("#chkSong"+trackNum).is(":checked")) {
                          //alert("checked")
                        } else if(trackNum < trackCount) {
                          //alert("not checked")
                          trackNum++;
                          if($("#chkSong"+trackNum).is(":checked")) {
                          //alert("checked")
                          } else if(trackNum < trackCount) {
                            //alert("not checked")
                            trackNum++;
                          }                       
                        }
                      }
                    }
                  }                      
                }
                
                ////console.log("trackNum: " + trackNum)

                if(trackNum === trackCount) {                        
                    trackNum = 0
                    //console.log("Reset trackNum: " + trackNum)
                } 
                selectedSong.value = trackNum                    

                trackPath = "/" + selType + "/" + selLanguage + "/" + selGenre + "/" + selSubgenre + "/" + selArtist + "/" + selAlbum + "/" + songs[trackNum].Title

                if(selType == "Singles") {
                    trackPath = "/" + selType + "/" + selLanguage + "/" + selGenre + "/" + selSubgenre + "/" + selArtist + "/" + selAlbum + "/" + songs[trackNum].Title
                }
                $(".play").css("background-color","purple")
                $("#btn"+trackNum).css("background-color","green")
                //alert("trackPath: " + trackPath) 
                ////console.log("trackNum: " + trackNum);
                ////console.log("Current Song: " + rootpath + trackPath)
                listen.currentTime = 0;
                //curSong.innerHTML = song[0].DisplayTitle
                listen.setAttribute( 'src', rootpath + trackPath)
                ////console.log("Preview Play mode")
                ////console.log("this.currentTime: " + this.currentTime)                     
              }                
            }, false);
        }
        else {                
          //curArtist.value = selArtist //reversed for singles only
          //curAlbum.value =  selAlbum //reversed for singles only

          //playerArtist.value = doc.album
          //trackNumber.value = trackNum
          //curSong.value = song[1].DisplayTitle
                              
          //lert("Playing: " +  songs[1].DisplayTitle)
          //rootpath="https://storage.cloud.google.com/staging.deploy-template-397316.appspot.com/%5B1964-a%5D%20Meet%20The%20Beatles%20(US)/"
          //rootpath="https://storage.cloud.google.com/staging.deploy-template-397316.appspot.com"
                  //https://storage.cloud.google.com/staging.deploy-template-397316.appspot.com

          // https://storage.cloud.google.com/staging.deploy-template-397316.appspot.com/%5B1964-a%5D%20Meet%20The%20Beatles%20(US)/01-I%20Want%20To%20Hold%20Your%20Hand.mp3
          // https://storage.cloud.google.com/staging.deploy-template-397316.appspot.com/English/Rock/Classic Rock/Beatles/[1963-b] With The Beatles/01-It Won't Be Long.mp3
             
             https://storage.cloud.google.com/staging.deploy-template-397316.appspot.com/%5B1964-a%5D%20Meet%20The%20Beatles%20(US)/04-It%20Won't%20Be%20Long.mp3

          //alert("Currently Playing: " + rootpath + trackPath)
          //alert("1: Currently Playing: " + rootpath + trackPath)
          //alert("2: Currently Playing: " + rootpath + selAlbum + "/" + song.Title)
          //listen.setAttribute( 'src', rootpath + selAlbum + "/" + song.Title) 
          trackPath = trackPath.replace(/ /g, "%20")
          //alert("trackPath: " + rootpath + trackPath) 
          //$(".divPlayer").show()
          //divPlayer.classList.remove("hidden")
          listen.setAttribute( 'src', rootpath + trackPath) 
          // https://storage.cloud.google.com/staging.deploy-template-397316.appspot.com/%5B1964-a%5D%20Meet%20The%20Beatles%20(US)/01-I%20Want%20To%20Hold%20Your%20Hand.mp3          
          //play(rootpath + selAlbum + "/" + song.Title)
          play(rootpath + trackPath)
          
          ////console.log("Normal Play mode")                 
          ////console.log("listen.currentTime: " + listen.currentTime) 
          //divArtist.innerHTML = doc.album
          //divAlbum.innerHTML = doc.artist
          //divTrackTitle.innerHTML = song[0].DisplayTitle

          listen.addEventListener("ended", function() {
            trackNum++;                
            trackPath = "/" + selType + "/" + selLanguage + "/" + selGenre + "/" + selSubgenre + "/" + selArtist + "/" + selAlbum + "/" + songs[trackNum].Title       
            if(selType == "Singles") {
                trackPath = "/" + selType + "/" + selLanguage + "/" + selGenre + "/" + selSubgenre + "/" + selArtist + "/" + selAlbum + "/" + songs[trackNum].Title
            }

            //alert("trackPath: " + rootpath + trackPath)  
            //console.log("curTrack: " + trackNum)                       
            //console.log("next Song: " + rootpath + trackPath)
            //curSong.innerHTML = doc.songs[trackNum].Song[0].DisplayTitle

            listen.currentTime = 0;
            
            //listen.setAttribute( 'src', rootpath + trackPath)            
            play(rootpath + trackPath) 
            //console.log("Normal Play mode")                                  
            //console.log("listen.currentTime 2: " + listen.currentTime) 
          });
        }         
      }
      
      const setYT = (language) => {  
        //alert("language: "+language)      
        selLanguage.value = language
        //alert("selLanguage.value: "+selLanguage.value)
        
        //$(".divContainer").hide() 

        curLevel.value = "Youtube"
        //alert("curLevel.value: "+curLevel.value)
                   
        //$(".divYTContainer").show()         
        $(".divYoutubes").show()  
        $(".divSearchId").show()
        $(".divSearch").show() 
        
        if(selLanguage.value=="Spanish") {
         //alert("divYTSpanishGenres")  
                    
          $("#btnSearchIdSpanish").css("background-color", "green")    
          $("#btnSearchIdSpanish").css("color", "white")    
          $("#btnSearchIdEnglish").css("background-color", "transparent")  
          $("#btnSearchIdEnglish").css("color", "black")    
          $("#btnSearchIdMembers").css("background-color", "transparent")   
          $("#btnSearchIdMembers").css("color", "black")   
          $(".divYTSpanishGenres").show()      
          $(".divYTEnglishGenres").hide() 
          $(".divYTMembers").hide()           
        } else if(selLanguage.value=="English") {
          //alert("divYTEnglishGenres")
          $("#btnSearchIdSpanish").css("background-color", "transparent")  
          $("#btnSearchIdSpanish").css("color", "black")   
          $("#btnSearchIdEnglish").css("background-color", "green") 
          $("#btnSearchIdEnglish").css("color", "white")   
          $("#btnSearchIdMembers").css("background-color", "transparent")   
          $("#btnSearchIdMembers").css("color", "black")  
          $("#divYTSpanishGenres").hide()      
          $("#divYTEnglishGenres").show()
          $("#divYTMembers").hide()            
        } else if(selLanguage.value=="Members") {
          //alert("divYTMembers")
          $("#btnSearchIdSpanish").css("background-color", "transparent")  
          $("#btnSearchIdSpanish").css("color", "black")   
          $("#btnSearchIdEnglish").css("background-color", "transparent")    
          $("#btnSearchIdEnglish").css("color", "black") 
          $("#btnSearchIdMembers").css("background-color", "green") 
          $("#btnSearchIdMembers").css("color", "white")  
          $("#divYTSpanishMembers").hide()
          $("#divYTEnglishGenres").hide()      
          $("#divYTMembers").show() 

          var currUser = toTitleCase(curUser.value)
          setYTGenre(currUser)                           
        }
        $("#divFooter").show()

        /*
        if(selLanguage.value == "" && selGenre.value == "") {
          //alert("1")
          $("#divYoutubes").css('max-height','100px')
        } else if(selLanguage.value != "" && selGenre.value == "") {
          //alert("2")
          $("#divYoutubes").css('max-height','100px')
        } else if(selLanguage.value != "" && selGenre.value != "") {
          //alert("3")
          $("#divYoutubes").css('max-height','100px')
        }    
        */

        //selGenre.value = ''        
      }

      const setYTGenre = (genre) => {
        
        selGenre.value = genre
        //alert("1: genre: "+genre)
        $(".divContainer").hide()
        $(".imgGenre").css("border","3px solid grey")        
        
        $("#divIFRYouTubes").hide()
        $("#divFooter").show()
        $(".divSearch").show()
        $("#divYoutubes").css('max-height','400px')
        $("#divYoutubes").show()        
        $("#divYTContainer").show()
        //alert("genre: "+genre)
        $("#img"+genre.replace(' ','').replace(' ','')).css("border","5px solid yellow")
        $("#img"+genre.replace(' ','').replace(' ','')).css("padding","5px solid yellow")
        $("#div"+genre.replace(' ','').replace(' ','')).css("border","5px solid yellow")
        $("#div"+genre.replace(' ','').replace(' ','')).css("padding","5px solid yellow")
        //alert("2: genre: "+genre)
      }

      const showSpanishYT = () => {
        $("#divSpanishYT").show()
        $("#divEnglishYT").hide()
        selLanguage.value = "Spanish"

        $("#btnBack").show() 
        $("#divTypes").hide()   
        $("#divLanguages").hide()   
        $("#divSubgenres").hide()
        $("#divArtists").hide()
        $("#divAlbums").hide() 
      }

      const showMembers = (i,type) => {  
        //alert("type: " + type);
        selectedType.value = i        
        selType.value = type        
        
        $(".divContainer").hide()   
        $("#divMembers").show()  
        
        $("#lblCollectionType").html(type)
      }

      const showPlaylists = (i,type) => {  
        //alert("Playlists: " + type);
        selectedType.value = i        
        selType.value = type        
        
        $(".divContainer").hide()  
        $("#btnBack").show()  
        $("#divFooter").show()   
        $("#divPlaylists").show()  
        
        $("#lblCollectionType").html(type)
      }

      const showDJ = (i,type) => {  
        //alert("DJ: " + type);
        selectedType.value = i        
        selType.value = type        
        
        $(".divContainer").hide()  
        $("#btnBack").show()  
        $("#divFooter").show()   
        $("#divDJ").show()  
        
        $("#lblCollectionType").html(type)

        //window.location.href = "http://localhost:8100?addDJ=1";
      }

      const playYoutubes = (i,youtubePath) => {
       // alert("youtubePath: " + youtubePath)

        $(".divContainer").hide()

        $("#btnBack").show() 
        $("#ifrYouTubes").attr('src',youtubePath);
        $("#divIFRYouTubes").show()          
        
        //$("#divYoutubes").css('max-height','30px')
        
        $(".divPlayer").hide()
        $("#divYoutubes").show()
        $("#btnBack").show() 
        $("#lblCollectionType").html("Youtube Videos")
        $("#divYTContainer").show() 
        $("#divFooter").show()
        $("#btnBack").focus()
        
      }
      
      const showSelectedGenrePlayLists = (i,genre) => { 
        //alert("i: " + i)        
        //alert("genre id: " + genre.id)        
        //alert("selected playlist  genre: " + genre.genre)        
        selectedGenre.value = genre.id        
        selGenre.value = genre.genre      
        
        //alert("selectedGenre: " + selectedGenre.value)        
        //alert("selGenre: " + selGenre.value)        

        $(".divContainer").hide()
        $("#btnBack").show() 

        $("#divSelectedGenrePlaylists").show() 
        $("#lblCollectionType").html(selGenre.value)                  
      }
      
      const showGenreSelectedPlayList = (j,playlist) => {  
        //alert("Selected j: "+j) 
        //alert("playlist.playlist: "+playlist.Playlist)   
                
        selectedPlaylist.value = j
        selPlaylist.value = playlist.Playlist
        //alert("selectedPlaylist.value: "+selectedPlaylist.value)
        //alert("selPlaylist.value: "+selPlaylist.value)

        $(".divContainer").hide()
        $("#btnBack").show() 

        $("#divFooter").show() 
        $("#divGenreSelectedPlaylistSongs").css('max-height','525px')
        $("#divGenreSelectedPlaylistSongs").show() 
        $("#lblCollectionType").html(selPlaylist.value)
      }

      const showGenreSelectedPlayListSongs = (i,song) => {  
        //alert(song.song)
        //alert("handleClick: "+j+" "+selType+" "+selLanguage+" "+selGenre+" "+selSubgenre+" "+selArtist+" "+selAlbum+" "+JSON.stringify(song)+" "+JSON.stringify(songs))       
        //alert("songs: " + song.Title)       
        //alert("songs count: " + songs.length)       
        //alert("songs j: " + songs[j].Title)  
        selectedSong.value = i     
        //alert("song: " + song.song)       

        //alert("song Display title: " + song.DisplayTitle)       
        //alert("doc.songs[index].Song[0].DisplayTitle: " + doc.songs[index].Song[0].DisplayTitle)       
        
        if(song.display != null) {
          //selSong.value = song.display
          let trackPath = ""
          let trackNum = i    
          //alert("trackPath: " + trackPath)                   
          //alert("trackNum: " + trackNum)                   

          var selType = song.song.split("/")[0]
          var selLanguage = song.song.split("/")[1]
          var selGenre = song.song.split("/")[2]
          var selSubgenre = song.song.split("/")[3]
          var selArtist = song.song.split("/")[4]
          var selAlbum = song.song.split("/")[5]
          var selSong = song.song.split("/")[6]


          //alert(selType.value+"/"+selLanguage.value+"/"+selGenre.value+"/"+selSubgenre.value+"/"+selArtist.value+"/"+selAlbum.value+"/"+selSong.value)  

          //trackPath = "/" + selType + "/" + selLanguage + "/" + selGenre + "/" + selSubgenre + "/" + selArtist + "/" + selAlbum + "/" + selSong
          trackPath = "/"+song.song
          //alert("trackPath: " + trackPath) 
          //if(selType == "Singles") {
              //trackPath = "/" + selType + "/" + selLanguage + "/" + selGenre + "/" + selSubgenre + "/" + selArtist + "/" + selAlbum + "/" +  selSong
          //}

          //alert("trackPath is : " + trackPath)
          ////console.log("Current Track: " + curTrack);
          //alert("Current Song: " + rootpath + trackPath)
          //alert("previewFlag: " + previewFlag)
          //alert("previewFlag.value: " + previewFlag.value)
          
          let trackCount = 2

          ////console.log("trackCount: " + trackCount)
          ////console.log("index: " + index)

          //var starttime = 7;  // start at 7 seconds
        
          var endtime = 4;    // stop at 5 seconds   
          //alert("Go")                    
          if($("#chkPreview").is(":checked")) {
            
              ////console.log("previewFlag: " + previewFlag)           
              //$("#chkPreview").prop("checked", true);
              //console.log("trackCount: " + trackCount)   
              //console.log("trackNum: " + trackNum)   
              //alert("trackNum: " + trackNum)              
              
              if(trackNum === trackCount) {                        
                  trackNum = 0
                  //console.log("Reset trackNum: " + trackNum)
              }

              //curSong.innerHTML = song.DisplayTitle
            
              listen.setAttribute( 'src', rootpath + trackPath) 
              listen.addEventListener("timeupdate", function() { 
                  //console.log("previewFlag: " + previewFlag)
                  //console.log("trackCount: " + trackCount)   
                  //console.log("trackNum: " + trackNum) 
                  //console.log("this.currentTime: " + this.currentTime)   

                  if(trackNum === trackCount) {                        
                    trackNum = 0
                    //console.log("Reset trackNum: " + trackNum)
                  }
                  trackNum++
                  $("#chkSong"+trackNum).focus()
                  if (this.currentTime >= endtime && $("#chkPreview").is(":checked")) {                       
                      //this.pause();
                      //trackNum++;                       
                      if($("#chkSong"+trackNum).is(":checked")) {
                        //alert("checked")
                      } else if(trackNum < trackCount) {
                        //alert("not checked")
                        //trackNum++; 
                        if($("#chkSong"+trackNum).is(":checked")) {
                          //alert("checked")
                        } else if(trackNum < trackCount) {
                          //alert("not checked")
                          //trackNum++;                       
                          if($("#chkSong"+trackNum).is(":checked")) {
                            //alert("checked")
                          } else if(trackNum < trackCount) {
                            //alert("not checked")
                            //trackNum++;                       
                            if($("#chkSong"+trackNum).is(":checked")) {
                              //alert("checked")
                            } else if(trackNum < trackCount) {
                              //alert("not checked")
                              //trackNum++;                       
                            }
                            if($("#chkSong"+trackNum).is(":checked")) {
                              //alert("checked")
                            } else if(trackNum < trackCount) {
                              //alert("not checked")
                              //trackNum++;                       
                              if($("#chkSong"+trackNum).is(":checked")) {
                                //alert("checked")
                              } else if(trackNum < trackCount) {
                                //alert("not checked")
                                //trackNum++;
                                if($("#chkSong"+trackNum).is(":checked")) {
                                //alert("checked")
                                } else if(trackNum < trackCount) {
                                  //alert("not checked")
                                  //trackNum++;
                                }                       
                              }
                            }
                          }
                        }                      
                      }
                    
                      //alert("trackNum: " + trackNum)
                      //alert("trackCount: " + trackCount)
                      if(trackNum === trackCount) {                        
                        trackNum = 0
                        //console.log("Reset trackNum: " + trackNum)
                        //alert("Reset trackNum: " + trackNum)
                      }
                    
                      //alert("trackNum: " + trackNum)

                      if(trackNum === trackCount) {                        
                          trackNum = 0
                          //console.log("Reset trackNum: " + trackNum)
                      } 
                      selectedSong.value = trackNum                    
                      //trackNum++;
                      trackPath = "/" + songs[trackNum].song

                      if(selType == "Singles") {
                          trackPath = "/" + selType + "/" + selLanguage + "/" + selGenre + "/" + selSubgenre + "/" + selArtist + "/" + selAlbum + "/" + songs[trackNum].song
                      }
                      $(".play").css("background-color","purple")
                      $("#btn"+trackNum).css("background-color","green")
                      //alert("trackPath: " + trackPath) 
                      ////console.log("trackNum: " + trackNum);
                      ////console.log("Current Song: " + rootpath + trackPath)
                      listen.currentTime = 0;
                      //curSong.innerHTML = song[0].DisplayTitle
                      listen.setAttribute( 'src', rootpath + trackPath)
                      ////console.log("Preview Play mode")
                      ////console.log("this.currentTime: " + this.currentTime)                     
                  }                
              }, false);
          }
          else {                
              //curArtist.value = selArtist //reversed for singles only
              //curAlbum.value =  selAlbum //reversed for singles only

              //playerArtist.value = doc.album
              //trackNumber.value = trackNumyespla
              //curSong.value = song[0].DisplayTitle
                                  
              ////console.log("Playing: " +  songs[0].DisplayTitle)
              //alert("Currently Playing: " + rootpath + trackPath) 
              listen.setAttribute( 'src', rootpath + trackPath) 
              play(rootpath + trackPath)
              ////console.log("Normal Play mode")                 
              ////console.log("listen.currentTime: " + listen.currentTime) 
              //divArtist.innerHTML = doc.album
              //divAlbum.innerHTML = doc.artist
              //divTrackTitle.innerHTML = song[0].DisplayTitle

              listen.addEventListener("ended", function() {
                trackNum++;                
                trackPath = "/" + selType + "/" + selLanguage + "/" + selGenre + "/" + selSubgenre + "/" + selArtist + "/" + selAlbum + "/" + songs[trackNum].Title       
                if(selType == "Singles") {
                    trackPath = "/" + selType + "/" + selLanguage + "/" + selGenre + "/" + selSubgenre + "/" + selArtist + "/" + selAlbum + "/" + songs[trackNum].Title
                }

                //alert("trackPath: " + rootpath + trackPath)  
                //console.log("curTrack: " + trackNum)                       
                //console.log("next Song: " + rootpath + trackPath)
                //curSong.innerHTML = doc.songs[trackNum].Song[0].DisplayTitle

                listen.currentTime = 0;
                
                //listen.setAttribute( 'src', rootpath + trackPath)
                play(rootpath + trackPath) 
                //console.log("Normal Play mode")                                  
                //console.log("listen.currentTime 2: " + listen.currentTime) 
            });
          } 
          $(".divRateIt").show()
          
          $("#lblCollectionType").html(song.display)
        }
      }
      
      const keepIt = (keep) => {
        //alert("keep: "+keep)
        //alert("selectedSong.value: "+selectedSong.value)
        
        if(keep==1) {
          $("#chkSong"+selectedSong.value).prop('checked',true)
        } else if(keep==0){
          $("#chkSong"+selectedSong.value).prop('checked',false)
        }
      }

      const setSearchByString = (searchByString) => {       
        //alert("searchByString: " + searchByString)
        selSearchByString.value = searchByString
        //alert("selSearchByString: " + selSearchByString.value)
      }

      const setLanguage = (id,language) => {
        //alert("language: " + language)
        selectedLanguage.value = id
        selLanguage.value = language
        //alert("selectedLanguage.value: " + selectedLanguage.value)
        //alert("selLanguage.value: " + selLanguage.value)

        //$(".AccLanguage").header = selLanguage.value
      }

      const setGenre = (id,genre) => {
        //alert("genre: " + genre)
        selectedGenre.value = id
        selGenre.value = genre
      }

      const setSubgenre = (id,subgenre) => {
         //alert("subgenre: " + subgenre)
         selectedSubgenre.value = id
         selSubgenre.value = subgenre
         $(".searchArtist").show()
      }

      const setArtist = (id,artist) => {
         //alert("artist: " + artist)
         selectedArtist.value = id
         selArtist.value = artist
      }

      const createPLSearchString = async (genre) => {
        //alert("createPLSearchString: " + genre)
        if(playlistSearch.value=='') {
          playlistSearch.value += genre
        } else {
          playlistSearch.value += ","+genre               
        }
        //alert(playlistSearch.value)                   
      }

      const playMusic = (i,title) => {  
        //alert(i)
       
        //var obj = jukebox
        //alert(JSON.stringify(obj[0].Languages))
        //var obj = jukebox[0].Languages
        
        //alert(JSON.stringify(obj))
        //alert(selectedLanguage.value+"/"+selectedGenre.value+"/"+selectedSubgenre.value+"/"+selectedArtist.value+"/"
        //+selectedAlbum.value+"/"+title.replace(" ","%20").replace(" ","%20").replace(" ","%20").replace(" ","%20"))

        //alert(JSON.stringify(obj))
        
        //alert(title)
        //alert(play)
        //var track = rootPath+"/"+selType.value+"/"+selLanguage.value+"/"+selGenre.value+"/"+selSubgenre.value+"/"+selArtist.value+"/"
        //+selAlbum.value+"/"+title.replace(" ","%20").replace(" ","%20").replace(" ","%20").replace(" ","%20")                      
        //let song = document.getElementById("listen");  
        $("#divFooter").show()          
        listen.setAttribute('src',title);
      }

      const handleSubmit = async () => {
        // loop through all records in songs.json array
        //get document based on language, genre, subgenre, artist, album query
        //update songs in doc based on query
        
        let playList = [
          { 'artist': 'Bronco', 'title': 'Adoro'},
          { artist: 'Bronco', title: 'Dos Mujeres Un Camino', id: Math.floor(Math.random() * 1000000) },
          { artist: 'Bronco', title: 'Dejame Amarte Otra Vez', id: Math.floor(Math.random() * 1000000) },
          { artist: 'Bronco', title: 'La Reganona', id: Math.floor(Math.random() * 1000000) },
          { artist: 'Bronco', title: 'No Quiero Volver', id: Math.floor(Math.random() * 1000000) },
          { artist: 'Bronco', title: 'Prision De Amor', id: Math.floor(Math.random() * 1000000) },
          { artist: 'Bronco', title: 'Un Fin De Semana', id: Math.floor(Math.random() * 1000000) },
          { artist: 'Bronco', title: 'A Que Le Tiramos', id: Math.floor(Math.random() * 1000000) },
          { artist: 'Bronco', title: 'Mi Chica Dificil', id: Math.floor(Math.random() * 1000000) },
          { artist: 'Bronco', title: 'Que No Me Olvides', id: Math.floor(Math.random() * 1000000) }
        ]

        const newSong = null
        for(var i=0;i<playList.length;i++)
        {
          //alert('Artist: ' + playList[i].artist + ' Title: ' + playList[i].title)
          //newSong = {
          //    title: playList[i].title,
          //    artist: playList[i].artist,
          //    id: Math.floor(Math.random() * 1000000)
          //}
          //alert('Artist: ' + playList[i].artist + ' Title: ' + playList[i].title)
          await updateDoc({
              songs: [...props.document.songs, playList[i]]
          })
          //title.value = ''
          //artist.value = ''
        }
      }

      const selYTArtist = ref();
      ////console.log("youtubes: " + JSON.stringify(youtubes._rawValue[1].artist))
      //alert("youtube artists: " + youtubes[1].artist)
      //var ytArtists = ref(youtubes.artist);      
      //alert("youtube artists: " + JSON.stringify(ytArtists))

      return { setSearchBy,selSearchBy,playlistSearch,curPLLanguage,prevTime,splGenres,eplGenres,masterPlaylist,createPlaylist, user,curUser,youtubeLinks,youtubeplaylists,selYTLanguage,selYTGenre,youtubes,members,curLevel,previewFlag,rootpath,
        selSearchByString,jukebox,selectedType,selectedLanguage,selectedGenre,selectedSubgenre,selectedArtist,selectedAlbum,selectedSong,selType,selLanguage,selGenre,dj,
        selSubgenre,selArtist,selAlbum,selSong,types,active,selectedMember,selMember,selectedPlaylist,selPlaylist,title,artist,requests,
        createPLSearchString,setPreview,showGenreSelectedPlayListSongs,showSelectedGenrePlayLists,showGenreSelectedPlayList,keepIt,setYT,setYTGenre,playYoutubes,showYoutubes,showMembers,handleClick,goBack,showTypes,showLanguages,showGenres,
        showPlaylists,toggleDivRateIt,showSubgenres,showArtists,showAlbums,showSongs,setSearchByString,setLanguage,setGenre,setSubgenre,setArtist,playMusic,
        handleSubmit,handlePlaylist,blur,showDJ,handleDJ,addToDJ,documents,scrollAlbums,Alpha,showKB,newDocs,selArtists,uniqueArray,curArtist,showColors,setColorArray }
    }         
  }
</script>
<style scoped>
     .w3-card-4 {
        display: inline-flexbox;
        margin: auto;
        margin-top: -5px;
        margin-bottom: 5px;
        width: 100%;
        background-color: rgb(0, 0, 0);
        height: 340px;
        padding-top: 0px;        
    }
    .user-search {      
        text-align: left;    
        font-size: 14px;
        font-weight: 600;
        color:white;
        border:solid blue 0px;
        width:320px;
        height: 34px;
        background-color: transparent;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 5px;
        margin-top: 1px;
        margin-bottom: 0px;
    }
    .header-cont {
        margin: auto;
        height: 50px;
        padding-top: 0px;
    }
    .image-cont {
        display: flex;
        margin: auto;
        justify-content: center;
        border: solid black 10px;
        background-color: rgb(255, 255, 255);
        height: 100%;
    }

    .member-cont {
        display: flex;
        margin: auto;
        justify-content: center;
        border: solid black 10px;
        background-color: rgb(255, 255, 255);
        height: 100%;
    }
    .footer-cont {
        margin: auto;
        height: 50px;
        padding-top: 5;
    }    
    .single-song {
        display: flex;
        border: solid rgb(60, 0, 128) 0px;
        margin: 0px;
    }
    .div-thumbnail {    
        text-align: left;
        border: solid 0px;
    }
    .thumbnail {
        display: flex;
        border-radius: 0px;
        margin-left: 6px;
        margin-top: 12px;
        border: solid black 1px;
        width: 96%;
        height: 90%;
        z-index: -1;        
    }
    .single {
        margin-top: 0px;
        border: solid 0px;
        height: 30px;
    }
    .single:hover {
        transform: scale(1.03);
        transition: all ease 0.2s;
    }
    .check {
        padding: 10px;
    }
    .add-song {
        text-align: center;
        margin-top: 40px;
    }
    form {
        max-width: 100%;
        text-align: left;
    }
    .trackNumber {
        display: none;
    }
    .trackTitle {
        margin-left: 0px;
        background-color: rgba(229, 217, 207, 0.435);    
        text-align: left;
        border: solid black 1px;
        border-radius: 1;
        color:rgb(0, 0, 0);
        font-size: 15px;
        font-weight: 700;
        height: 35px; 
        margin-bottom: 0px;     
        margin-top: 0px;  
        padding-left: 10px;
    }
    .trackTitle:hover{
        background-color: rgb(130, 166, 133);
        color: white;
    }
    .deleteList {
        padding-left: 0px;
    }
    .deletePlaylist {
        margin-bottom: 0px;
        background-color: rgb(94, 98, 156);
        text-align: left;
        margin-top: 0px;
        border: solid 0px;
        border-radius: 1;
        color:white;
        font-size:  16px;
        padding-top: 0px;
    }
    .deletePlaylist:hover{
        background-color: rgba(133, 129, 131, 0.486);
        color: black;
    }
    button {
        background: rgb(216, 211, 201);
        color: black;
        width: 315px;
        font-size: 10px;
        height: 35px;
        margin-top: 0px;
        text-align: left;
        padding-top: 5px;
    }
    .player {
        display: none;
        margin: auto;
        text-align: center;
    }
    p {
        margin-top: 0px;
    }
    .playlist-details {
        margin-top: 0px;
        border: solid black 0px;
    }
    .trackNumber {
        color: blue;
        margin-right: 0px;
    }
    .artist{
        margin: auto;
        text-align: center;        
        color: rgb(0, 0, 0);
        font-size: 16px;
        border: solid black 0px;
        font-weight: 800;
        border-radius: 10px;
        width: 90%;    
        margin-top: 20px;    
    }
    .album{
        text-align: center;
        color: rgb(0, 0, 1);
        font-size: 18px;
        margin-bottom: 5px;
        border: solid black 0px;
        font-weight: 800;
    }
    .w3-check {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 0px;
        margin-bottom: 0px;        
    }
    .material-icons {
        color: #000000a2;
        cursor: pointer;
        border: solid black 0px;
        margin-top: 5px;
        margin-right: 5px;
    }
    .artist-name {
        text-align: center;
        font-size: 20px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        font-weight: 600;
        border: solid 0px;
        border-radius: 0px;
        padding-left: 10px;
    } 

    input, textarea {
        border: 0;
    }

    label {
        margin-top: 0px;
    }
    .rainbowder {
        --angle: 0deg;
        width: 50vmin;
        height:  50vmin;
        border: 2vmin solid;
        border-image: conic-gradient(from var(--angle), rgb(91, 128, 49)) 1;                      
        animation: s rotate reverse infinite;
    }

    .rainbowder2 {
        --angle: 0deg;
        width: 5vmin;
        height:  50vmin;
        border: 1vmin solid;
        border-image: conic-gradient(from var(--angle), rgb(223, 158, 19)) 1;       
        
        animation: 2s rotate linear infinite;
    }
    .mat {
        animation-name: rotate;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-delay: 250ms;
    }
    .record {
        animation-name: rotate;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-delay: 250ms;
    }
    .beatles-album  {
        animation-name: rotate;
        animation-duration: 3s;
        animation-iteration-count: 1;
        animation-delay: 250ms;
    }
    .cueRecord {    
        animation: cueRecord 3s 1 linear;                   
    }
    .playRecord {
        animation: playRecord 175s linear;
    }
    .resetNeedle {
        animation: resetNeedle 3s 1 linear;
    }
    @keyframes cueRecord {        
        0%      { transform: rotate(0deg); }        
        10%      { transform: rotate(5deg); }              
        25%      { transform: rotate(10deg); }              
        50%      { transform: rotate(15deg); }              
        100%      { transform: rotate(23deg); }              
    }
    @keyframes playRecord {
        from{ transform: rotate(18deg); }
        to{ transform: rotate(30deg); }
    }
    @keyframes resetNeedle {        
        0%      { transform: rotate(30deg); }                          
        10%    { transform: rotate(15deg); }        
        20%    { transform: rotate(10deg); }        
        30%    { transform: rotate(5deg); }        
        100%    { transform: rotate(0deg); }        
    }    
    @keyframes rotate {
        from{ transform: rotate(-360deg); }
        to{ transform: rotate(360deg); }
    }    
    @property --angle {
        syntax: '<angle>';
        initial-value: 0deg;
        inherits: false;
    }
    input[type=range][orient=vertical]
    {
        writing-mode: bt-lr; /* IE */
        -webkit-appearance: slider-vertical; /* Chromium */
        width: 8px;
        height: 175px;
        padding: 0 5px;
    }

    * {
    box-sizing: border-box;
    }

    body {
    font: 16px Arial;  
    }

    .autocomplete {
    /*the container must be positioned relative:*/
    position: relative;
    display: inline-block;
    }

    input {
    border: 1px solid transparent;
    background-color: #f1f1f1;
    padding: 10px;
    font-size: 16px;
    }

    input[type=text] {
    background-color: #f1f1f1;
    width: 100%;
    }

    input[type=submit] {
    background-color: DodgerBlue;
    color: #fff;
    cursor: pointer;
    }

    .autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
    }

    .autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff; 
    border-bottom: 1px solid #d4d4d4; 
    }

    .autocomplete-items div:hover {
    /*when hovering an item:*/
    background-color: #e9e9e9; 
    }

    .autocomplete-active {
    /*when navigating through the items using the arrow keys:*/
    background-color: DodgerBlue !important; 
    color: #ffffff; 
    }
</style>



